<section class="py-3 bg-light mb-30">
    <div class="container">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">{{ search }}</a></li>
      </ol>
    </div>
  </section>
  <section class="padding-bottom">
    <div class="container">
      <div class="row">
        <div *ngIf='searchProducts.data.length == 0' class="not-found">
          No Products Found
        </div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-4" *ngFor="let service of searchProducts.data">
          <div class="card card-product-grid bord-1">
            <a href="javascript:void(0)" (click)='productdetails(service.id)' class="img-wrap-2"> <img src="{{ service.service_image }}"> </a>
            <figcaption class="info-wrap text-center">
              <div>
                <a href="javascript:void(0)" (click)='productdetails(service.id)' class="">{{ service.service_name }}</a>
              </div>
              <!-- price.// -->
            </figcaption>
          </div>
        </div>
        <!-- col.// -->
      </div>
      <!-- row.// -->
    </div>
  </section>
  <!-- =============== SECTION BANNER =============== -->
  <section class="padding-bottom">
   <div class="container">
      <div class="row">
          <aside class="col-md-6">
              <div class="card card-banner-lg bg-dark">
                  <img src="assets/images/banners/banner4.jpg" class="card-img opacity">
                  <div class="card-img-overlay text-white">
                    <h2 class="card-title">Big Deal on Clothes</h2>
                    <p class="card-text" style="max-width: 80%">This is a wider card with text below and Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo ab quae nihil praesentium impedit libero possimus id vero</p>
                    <a href="#" class="btn btn-light">Discover</a>
                  </div>
               </div>
          </aside>
          <div class="col-md-6">
              <div class="card card-banner-lg bg-dark">
                  <img src="assets/images/banners/banner5.jpg" class="card-img opacity">
                  <div class="card-img-overlay text-white">
                    <h2 class="card-title">Great Bundle for You</h2>
                      <p class="card-text" style="max-width: 80%">Card with text below and Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo ab quae nihil praesentium impedit libero possimus id vero</p>
                    <a href="#" class="btn btn-light">Discover</a>
                  </div>
               </div>
          </div> <!-- col.// -->
      </div> <!-- row.// -->
   </div>
  </section>