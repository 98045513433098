<section class="padding-login">
    <div class="container">
       <div class="row">
          <div class="col-md-6">
             <img src="assets/images/banners/register.png">
          </div>
          <div class="col-md-6">
             <div class="card mx-auto bg-form">
                <article class="card-body">
                   <header class="mb-4">
                      <h4 class="card-title color-black">Sign In</h4>
                   </header>
                   <form #loginForm="ngForm" (ngSubmit)="login(loginForm.value)">
                      <!-- form-row end.// -->
                      <div class="form-group">
                         <label class="color-black">Email</label>
                         <input type="email" class="form-control" required placeholder="" ngModel #email="ngModel" name="email">
                         <small class="form-text text-muted">We'll never share your email with anyone else.</small>
                      </div>
                      <div class="form-group">
                         <div class="error" *ngIf="email.invalid && email.touched">
                            <p class="error-forms">Email is required</p>
                         </div>
                      </div>
                      <div class="form-group">
                         <label class="color-black">Password</label>
                         <input type="password" class="form-control" required  ngModel #password="ngModel" name="password">
                      </div>
                      <div class="form-group">
                         <div class="error" *ngIf="password.invalid && password.touched">
                            <p class="error-forms">password is required</p>
                         </div>
                      </div>
                      <div class="form-group">
                         <!-- <button [disabled]="registerForm.invalid" type="submit" class="btn btn-primary btn-block"> Register </button>
                            -->
                         <button [disabled]="loginForm.invalid" type="submit" class="btn btn-primary btn-block"> Login </button>
                      </div>
                   </form>
                </article>
                <!-- card-body.// -->
             </div>
             <!-- card .// -->
             <p class="text-center mt-4">Don't Have an account? <a href="javascript::void(0)" (click)="register()">Register</a></p>
             <br>
             <br>
          </div>
       </div>
    </div>
 </section>
