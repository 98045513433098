import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from '../service/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  categories: any;
  servicesByOneSubcategory: any;
  servicesinformation: any;
  year: number = 0;
  stop_year: number = 365;
  images = [
    { path: 'assets/images/brand-1.png' },
    { path: 'assets/images/brand-3.png' },
    { path: 'assets/images/brand-2.png' },
    { path: 'assets/images/brand-4.png' },
  ];
  service_images = [
    {
      img: 'assets/images/Picture6.jpg',
      title: 'Commercial cleaning',
    },
    {
      img: 'assets/images/Picture7.jpg',
      title: 'Residential Cleaning',
    },
    {
      img: 'assets/images/Picture8.jpg',
      title: 'Office Cleaning',
    },
    {
      img: 'assets/images/Picture9.jpg',
      title: 'Pest Control and Fumigation',
    },
    {
      img: 'assets/images/Picture10.png',
      title: 'Land Scaping',
    },
    {
      img: 'assets/images/Picture11.jpg',
      title: 'Plumbing',
    },
    {
      img: 'assets/images/Picture12.jpg',
      title: 'Electrical Engeneering',
    },
    {
      img: 'assets/images/Picture13.jpg',
      title: 'Concierge Services',
    },
    {
      img: 'assets/images/Picture14.jpg',
      title: 'Dredging',
    },
    {
      img: 'assets/images/Picture15.jpg',
      title: 'Beach Cleaning',
    },
    {
      img: 'assets/images/Picture16.jpg',
      title: 'Beach Cleaning',
    },
    {
      img: 'assets/images/Picture17.jpg',
      title: 'Beach Cleaning',
    },
    {
      img: 'assets/images/Picture18.jpg',
      title: 'Beach Cleaning',
    },
  ];
  constructor(private service: UserService, private router: Router) {}

  ngOnInit(): void {
    console.log(this.service_images);
    setInterval(() => {
      if (this.year != this.stop_year) {
        this.year++;
      }
    });

    this.service.getAllCategories().subscribe((categories) => {
      this.categories = categories;
    });

    this.service.getServicesByOneSubcategory().subscribe((services) => {
      this.servicesByOneSubcategory = services;
    });

    this.service.getServices().subscribe((success) => {
      this.servicesinformation = success;
    });
  }

  viewallproduct = (cat_id: any) => {
    this.router.navigate(['products/' + cat_id]);
  };

  getCategoryProducts = (cat_id: any) => {
    this.router.navigate(['products/' + cat_id]);
  };

  getSubcategory = (subcat_id: any) => {
    this.router.navigate(['subcategories/' + subcat_id]);
  };

  seachProduct = () => {
    const formData = new FormData();
    formData.append('name', 'alli');
  };

  redirectToProduct = (service_id: any) => {
    this.router.navigate([`products-details/${service_id}`]);
  };
}
