import { Component, OnInit } from '@angular/core';
import { UserService} from "../service/user.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor( private UserService:UserService, private Router:Router ) { }

  ngOnInit(): void {
  }

  register = (formValue:any) =>{
    this.UserService.userResigter(formValue).subscribe((success:any)=>{
      console.log(success);
      Swal.fire('You have been registered');
      this.Router.navigate(['login']);
    });
  }

  login = () =>{
    this.Router.navigate(['login']);
  }

}
