import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from "../service/user.service";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  
  category:any;
  subcategory:any;
  services:any;
  response:any;
  constructor( private ActivatedRoute:ActivatedRoute, private service:UserService, private Router:Router) { }

  ngOnInit(): void {
    let id = this.ActivatedRoute.snapshot.paramMap.get('slug');
    this.service.getServiceBySubcategory(id).subscribe((success)=>{
      this.response = success;
      this.subcategory = this.response;
      this.category = this.subcategory.category;
      this.services = this.subcategory.get_all_service_by_one_subcategory;
    });
  } 
 
  productdetails = (service_id:any) =>{
    this.Router.navigate(['products-details/' + service_id]);
  }
} 
