<section class="padding-login">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img src="assets/images/banners/register.png">
        </div>
        <div class="col-md-6">
          <div class="card mx-auto bg-form">
            <article class="card-body">
              <header class="mb-4">
                <h4 class="card-title color-black">Sign up</h4></header>
              <form #registerForm="ngForm" (ngSubmit)="register(registerForm.value)">
                <div class="form-row">
                  <div class="col form-group">
                    <label class="color-black">Name</label>
                    <input type="text" class="form-control" required placeholder="" ngModel #name="ngModel" name="name">
                  </div>
                  <!-- form-group end.// -->
                  <div class="col form-group">
                    <label class="color-black">Mobile</label>
                    <input type="text" minlength="10" maxlength="10" class="form-control" required placeholder="" ngModel #mobile="ngModel" name="mobile">
                  </div>
                  <!-- form-group end.// -->
                </div> 

                <div class="form-row">
                  <div class="col form-group">
                      <div class="error" *ngIf="name.invalid && name.touched">
                            <p class="error-forms">Name is required</p>
                    </div>
                  </div>
                  <!-- form-group end.// -->
                  <div class="col form-group">
                    <div class="error" *ngIf="mobile.invalid && mobile.touched">
                          <p class="error-forms">Mobile number is invalid</p>
                    </div>
                  </div>
                </div> 
                
                <!-- form-row end.// -->
                <div class="form-group">
                  <label class="color-black">Email</label>
                  <input type="email" class="form-control" required placeholder="" ngModel #email="ngModel" name="email">
                  <small class="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div class="form-group">
                  <div class="error" *ngIf="email.invalid && email.touched">
                          <p class="error-forms">Email is required</p>
                  </div>
                </div>
                <!-- form-group end.// -->
                <div class="form-group">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" checked="true" ngModel type="radio" name="gender" value="1">
                    <span class="custom-control-label color-black"> Male </span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" ngModel name="gender" value="0">
                    <span class="custom-control-label color-black"> Female </span>
                  </label>
                </div>
                <!-- form-group end.// -->
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="color-black">City</label>
                    <input type="text" class="form-control" required name="city" ngModel #city="ngModel">
                  </div>
                  <!-- form-group end.// -->
                  <div class="form-group col-md-6">
                    <label class="color-black">Country</label>
                    <select class="form-control" required name="country" ngModel #country="ngModel">
                      <option value=""> Choose...</option>
                      <option>Uzbekistan</option>
                      <option>Russia</option>
                      <option selected="">United States</option>
                      <option>India</option>
                      <option>Afganistan</option>
                    </select>
                  </div>
                  <!-- form-group end.// -->
                </div>
                <div class="form-row">
                  <div class="col form-group">
                      <div class="error" *ngIf="city.invalid && city.touched">
                            <p class="error-forms">city is required</p>
                    </div>
                  </div>
                  <!-- form-group end.// -->
                  <div class="col form-group">
                    <div class="error" *ngIf="country.invalid && country.touched">
                          <p class="error-forms">Country is required</p>
                    </div>
                  </div>
                </div>
                
                <div class="form-group">
                  <label class="color-black">Password</label>
                  <input type="password" class="form-control" required  ngModel #password="ngModel" name="password">
                </div>
                <div class="form-group">
                  <div class="error" *ngIf="password.invalid && password.touched">
                      <p class="error-forms">password is required</p>
              </div>
                </div>

                

                <div class="form-group">
                  <button [disabled]="registerForm.invalid" type="submit" class="btn btn-primary btn-block"> Register </button>
                </div>
                
              </form>
            </article>
            <!-- card-body.// -->
          </div>
          <!-- card .// -->
          <p class="text-center mt-4">Have an account? <a href="javascript::void(0)" (click)="login()">Log In</a></p>
          <br>
          <br>
        </div>
      </div>
    </div>
  </section>