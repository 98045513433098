<section>
    <div class="container-fluid tagline-sec box-shadowsec" style="background-image: url('assets/images/banners/guy-mopping-dust-sofa-apartment.jpg'); ">
       <div class="row justify-content-md-center">
          <div class="col-md-8 taglin-conte2">
             <h1 class="text-center" style="font-size:34px">DON'T JUST CLEAN, GET IT CLEANED THE "UNIK" WAY ...</h1>
             <p class="text-center">Established in 2011 to change the face of Commercial cleaning activities; we parade a talented team of experienced personnel who handle all projects with precise attention to detail and a personal touch</p>
          </div>
       </div>
    </div>
 </section>
 <section class="contact-page-sec pa">
    <div class="container">
       <div class="row">
          <div class="col-md-4">
             <div class="contact-info">
                <div class="contact-info-item">
                   <div class="contact-info-icon">
                      <i class="fas fa-map-marked"></i>
                   </div>
                   <div class="contact-info-text">
                      <h2>address</h2>
                      <span>UJS FACILITY MANAGEMENT</span>,
                      <span> Old Ashongman, Abladjei Post Box NT 639, </span>
                      <span>Accra Newtown, Accra, Ghana.</span>
                   </div>
                </div>
             </div>
          </div>
          <div class="col-md-4">
             <div class="contact-info">
                <div class="contact-info-item">
                   <div class="contact-info-icon">
                      <i class="fas fa-envelope"></i>
                   </div>
                   <div class="contact-info-text">
                      <h2>E-mail</h2>
                      <span>info@unikjanitorialservices.com</span>
                      <!-- <span>yourmail@gmail.com</span> -->
                   </div>
                </div>
             </div>
          </div>
          <div class="col-md-4">
             <div class="contact-info">
                <div class="contact-info-item">
                   <div class="contact-info-icon">
                      <i class="fa fa-phone" style="transform: rotate(
                         90deg);"></i>
                   </div>
                   <div class="contact-info-text">
                      <h2>Call</h2>
                      <span>Landline : + (233) 0302556038</span>
                      <span>Mobile : +(233) 0547555620, 0262761515</span>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <div class="row">
          <div class="col-md-8">
             <div class="contact-page-form" method="post">
                <h2>Get in Touch</h2>
                <form #contatForm="ngForm" (ngSubmit)="contactInformation(contatForm.value)">
                   <div class="row">
                      <div class="col-md-6 col-sm-6 col-xs-12">
                         <div class="single-input-field">
                            <input type="text" placeholder="Your Name" name="name" required ngModel #name="ngModel" name="name" />
                         </div>
                         <div class="form-group">
                           <div class="error" *ngIf="name.invalid && name.touched">
                              <p class="error-forms">Name is required</p>
                           </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                         <div class="single-input-field">
                            <input type="email" placeholder="E-mail" name="email" required ngModel #email="ngModel" />
                         </div>
                         <div class="error" *ngIf="email.invalid && email.touched">
                           <p class="error-forms">Email is required</p>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-xs-12">
                         <div class="single-input-field">
                            <input type="text" placeholder="Phone Number" name="phone" required ngModel #number="ngModel" />
                         </div>
                         <div class="error" *ngIf="number.invalid && number.touched">
                           <p class="error-forms">Mobile Number is required</p>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                         <div class="single-input-field">
                            <input type="text" placeholder="Subject" name="subject" required ngModel #subject="ngModel" />
                         </div>
                         <div class="error" *ngIf="subject.invalid && subject.touched">
                           <p class="error-forms">Subject Number is required</p>
                        </div>
                      </div>
                      <div class="col-md-12 message-input">
                         <div class="single-input-field">
                            <textarea  placeholder="Write Your Message" name="message" required ngModel #message="ngModel"></textarea>
                         </div>
                         <div class="error" *ngIf="message.invalid && message.touched">
                           <p class="error-forms">Message  required</p>
                        </div>
                      </div>
                      <div class="single-input-fieldsbtn">
                         <input type="submit" value="Send Now" [disabled]="contatForm.invalid" />
                      </div>
                   </div>
                </form>
             </div>
          </div>
          <div class="col-md-4">
             <div class="contact-page-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109741.02912911311!2d76.69348873658222!3d30.73506264436677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0be66ec96b%3A0xa5ff67f9527319fe!2sChandigarh!5e0!3m2!1sen!2sin!4v1553497921355" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="we-offer-area text-center bg-gray">
    <div class="container">
       <div class="row">
          <div class="col-md-12">
             <div class="site-heading text-center">
                <h2>WHAT REALLY MAKES <span>US DIFFERENT?</span></h2>
             </div>
          </div>
       </div>
       <div class="row our-offer-items less-carousel">
          <!-- Single Item -->
          <div class="col-md-3 col-sm-6 equal-height">
             <div class="item">
                <i class="fas fa-dharmachakra"></i>
                <h4>Highest Cleaning Standards</h4>
                <p>
                   Attention to detail is critical in Commercial cleaning and the Unik Janitorial Services team pride ourselves on our impeccable cleaning quality which is always delivered on time.
                </p>
             </div>
          </div>
          <!-- End Single Item -->
          <!-- Single Item -->
          <div class="col-md-3 col-sm-6 equal-height">
             <div class="item">
                <i class="fas fa-id-card-alt"></i>
                <h4>Customer Service</h4>
                <p>
                   From initial scoping through to the actual cleaning and post project follow up; our Customer Service is second to none - with our management team active in every project to ensure we exceed customer satisfaction.
                </p>
             </div>
          </div>
          <!-- End Single Item -->
          <!-- Single Item -->
          <div class="col-md-3 col-sm-6 equal-height">
             <div class="item">
                <i class="fas fa-bug"></i>
                <h4>Health and Safety</h4>
                <p>
                   We are committed to protection ur Clients and staff from the hazards of cleaning. All necessary Occupational Health & Safety rules and regulations are strictly followed; and our teams come equipped with modern cleaning equipments (checked and tagged regularly) and materials.
                </p>
             </div>
          </div>
          <!-- End Single Item -->
          <!-- Single Item -->
          <div class="col-md-3 col-sm-6 equal-height">
             <div class="item">
                <i class="fas fa-users"></i>
                <h4>Professional Cleaning Staff</h4>
                <p>
                  A cleaning job is only as good as the staff that do the work. All UJS Facility Management staff are
                  carefully selected, go through a thorough training process and come with police clearances. Most ongoing
                  cleaning projects are conducted by the same teams.
                </p>
             </div>
          </div>
          <!-- End Single Item -->
       </div>
    </div>
 </section>
