import { Component, OnInit } from '@angular/core';
import { UserService } from "../service/user.service";
import { CartserviceService } from "../service/cartservice.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  cartProduct:any = [];
  cartQty:number = 0;
  isLoggedIn:boolean = false;
  constructor(private service:UserService, private CartserviceService:CartserviceService, private Router:Router) { }
   
  categories:any;
  ngOnInit(): void {
     if(localStorage.getItem('user')){
       this.isLoggedIn = true;
     }
     this.CartserviceService.getProduct().subscribe((success:any)=>{
      this.cartProduct = success;
      this.cartQty = this.cartProduct.length;
    });
  }

  cart = () =>{
    this.Router.navigate(['cart']);
  }

  getSearchInformation = (formValue:any) =>{
    this.Router.navigateByUrl('/dummyroute', { skipLocationChange: true }).then(() =>
       this.Router.navigate(['search/'+formValue.search]));
  }

  aboutUs = () => {
    this.Router.navigate(['about']);
  }

  team = () =>{
    this.Router.navigate(['team']);
  }

  methodology = () =>{
    this.Router.navigate(['methodology']);
  }

  contactUs = () =>{
    this.Router.navigate(['contact-us']);
  }
}
