import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { CartComponent } from './cart/cart.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutComponent } from './layout/layout.component';
import { HttpClientModule } from "@angular/common/http";
import { MatSliderModule } from '@angular/material/slider';
import { ProductComponent } from './product/product.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchproductComponent } from './searchproduct/searchproduct.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { PaymentComponent } from './payment/payment.component';
import { TeamComponent } from './team/team.component';
import { MethodologyComponent } from './methodology/methodology.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    CartComponent,
    LayoutComponent,
    ProductComponent,
    ProductdetailsComponent,
    SearchproductComponent,
    RegisterComponent,
    LoginComponent,
    ProfileComponent,
    PaymentComponent,
    TeamComponent,
    MethodologyComponent,
    SubcategoriesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    IvyCarouselModule
  ],
  providers: [{provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
