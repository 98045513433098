import { Component, OnInit } from '@angular/core';
import { UserService } from "../service/user.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.css']
})
export class SubcategoriesComponent implements OnInit {

  public response:any;
  public subcategories:any;
  public category:any;
  constructor( private UserService:UserService, private ActivatedRoute:ActivatedRoute, private Router:Router ) { }

  ngOnInit(): void {
    let id = this.ActivatedRoute.snapshot.paramMap.get('id');
    this.UserService.getSubcategories(id).subscribe((success)=>{
      this.response = success;
      this.subcategories = this.response.subcategories;
      this.category = this.response.category;
      console.log(this.subcategories);
    });
  }

  redirectToService = (subcat_id:any) =>{
    this.Router.navigate([`products/${subcat_id}`]);
  }

}
