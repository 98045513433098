import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from "../service/user.service";
import { CartserviceService } from "../service/cartservice.service";

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit {

  id:any;
  loader:boolean = true;
  cartProduct:any = [];
  services:any;
  inclusion:any;
  exclusion:any;
  prices:any;
  active:number = 0;
  minValue:any = null;
  maxValue:any = null;
  itemName:any = null;
  currentPrice:any = 0;
  subTotal:any = 0;
  qty:any = 0;
  product_name:any = null;
  product_image:any = null;

  constructor(private ActivatedRoute:ActivatedRoute, private service:UserService, private Router:Router, private CartserviceService:CartserviceService) { }
  
  ngOnInit(): void {
    this.id = this.ActivatedRoute.snapshot.paramMap.get('slug');
    this.service.getServiceById(this.id).subscribe((success)=>{
      this.services = success;
      this.loader = false;
      this.inclusion = JSON.parse(this.services.data.inclusion);
      this.exclusion = JSON.parse(this.services.data.exclusion);
      this.prices = this.services.prices;
      this.prices.map((data:any, id:any)=>{
        if(id == 0){
          this.currentPrice = data.price;
          this.itemName = data.name;
          this.subTotal = data.price;
        }
      });
      this.minValue = this.services.data.min_value;
      this.maxValue = this.services.data.max_value;
      this.qty = this.services.data.min_value;
      this.product_name = this.services.data.service_name;
      this.product_image = this.services.data.service_image;
    });
  }

  changeKey = (key:number,id:any) =>{
    this.active = key;
    this.service.getPriceById(id).subscribe((response:any)=>{
      this.loader = false;
      this.currentPrice = response.price.price;
      this.itemName = response.price.name;
      this.product_name = this.services.data.service_name;
      this.product_image = this.services.data.service_image;
      this.subTotal = response.price.price;
      this.qty = this.minValue;
    });
  }

  increaseVal = () =>{
    if(this.maxValue-1 >= this.qty){
      this.qty++;
      this.subTotal = this.currentPrice * this.qty+'.00';
    }
  }

  decreaseVal = () =>{
    if(this.qty > this.minValue){
      this.loader = false;
      this.qty = this.qty-1;
      this.subTotal = this.currentPrice * this.qty+'.00';
    }
  }

  addToCart = () =>{
    this.cartProduct = {
      product_id : this.id,
      item_name : this.itemName,
      current_price : this.currentPrice,
      product_name : this.services.data.service_name,
      product_image : this.services.data.service_image,
      qty : this.qty,
      min_qty : this.minValue,
      max_qty : this.maxValue,
      total : this.subTotal,
    };
    this.CartserviceService.addToCart(this.cartProduct);
  }
}
