<section class="padding-bottom">
    <div class="container-fluid tagline-sec box-shadowsec" style="background-image: url('assets/images/banners/guy-mopping-dust-sofa-apartment.jpg'); ">
       <div class="row justify-content-md-center">
          <div class="col-md-8 taglin-conte2">
             <h1 class="text-center" style="font-size:34px">DON'T JUST CLEAN, GET IT CLEANED THE "UNIK" WAY ...</h1>
             <p class="text-center">Established in 2011 to change the face of Commercial cleaning activities; we parade a talented team of experienced personnel who handle all projects with precise attention to detail and a personal touch</p>
          </div>
       </div>
    </div>
 </section>
 <section class="padding-bottom">
    <div class="container">
       <h3 class="text-center">COMMERCIAL CLEANING SCHEDULE</h3>
       <div class="row">
          <div class="col-md-12">
             <div class="row">
                <div class="col-md-6 px-4 my-2">
                   <svg height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 450.78 450.78" style="enable-background:new 0 0 450.78 450.78;" xml:space="preserve">
                      <path d="M449.962,173.343L373.338,56.434c-0.521-0.796-1.26-1.427-2.128-1.817c0,0-108.317-49.534-113.373-47.804l-0.604,0.205
                         c-20.534,6.962-43.152,6.962-63.698-0.004l-0.593-0.201c-5.054-1.73-113.374,47.804-113.374,47.804
                         c-0.868,0.391-1.606,1.021-2.128,1.817L0.818,173.343c-1.286,1.962-1.035,4.553,0.602,6.231c1.3,1.334,32.496,32.807,89.373,45.624
                         c2.037,0.453,4.146-0.397,5.287-2.146l14.618-22.415l1.472,226.004c0.015,2.312,1.612,4.312,3.863,4.837
                         c35.771,8.355,72.564,12.533,109.357,12.533c36.793,0,73.586-4.178,109.357-12.533c2.25-0.525,3.848-2.525,3.863-4.837
                         l1.472-226.004l14.618,22.415c1.141,1.748,3.251,2.605,5.287,2.146c56.877-12.817,88.073-44.29,89.373-45.624
                         C450.997,177.896,451.247,175.305,449.962,173.343z M372.299,73.091l57.714,88.058c-14.914,10.884-41.974,28.08-77.646,40.02
                         l-11.193-17.162C358.297,156.778,368.417,99.246,372.299,73.091z M174.936,24.448c4.307,13.438,16.556,29.459,50.454,29.459
                         c33.897,0,46.147-16.021,50.454-29.459c0.174-0.542,0.307-1.09,0.414-1.639l5.578,2.513c-0.086,0.318-0.176,0.636-0.278,0.951
                         c-9.386,29.258-39.216,33.627-56.163,33.627c-16.951,0-46.788-4.368-56.174-33.63c-0.101-0.314-0.191-0.63-0.277-0.947l5.578-2.513
                         C174.629,23.357,174.762,23.905,174.936,24.448z M185.488,17.226c0.419-0.411,1.404-1.193,2.826-1.193
                         c0.426,0,0.891,0.07,1.392,0.241l0.63,0.215c22.605,7.663,47.503,7.663,70.098,0.004l0.642-0.219
                         c2.171-0.741,3.672,0.417,4.217,0.952c0.541,0.53,1.725,1.999,1.029,4.169c-4.787,14.938-18.559,22.512-40.931,22.512
                         c-22.373,0-36.145-7.574-40.932-22.512C183.763,19.225,184.947,17.756,185.488,17.226z M109.606,184.006l-11.193,17.162
                         c-35.672-11.94-62.732-29.136-77.646-40.02l57.714-88.058C82.363,99.246,92.483,156.778,109.606,184.006z M89.62,214.652
                         c-42.411-10.338-69.562-31.8-78.124-39.356l3.781-5.77c17.296,12.609,43.516,28.592,77.499,40.286L89.62,214.652z M328.636,422.619
                         c-67.635,15.191-138.855,15.191-206.492,0l-1.554-238.667c-0.002-0.315-0.086-0.698-0.201-1.08
                         c-0.285-0.938-0.748-1.804-1.299-2.616c-19.039-28.074-29.739-100.95-32.032-118.045l72.689-32.746
                         c4.906,15.168,19.949,40.434,65.649,40.434c45.692,0,60.733-25.268,65.638-40.434l72.689,32.746
                         c-2.294,17.095-12.994,89.971-32.032,118.045c-0.55,0.811-1.014,1.677-1.299,2.616c-0.116,0.382-0.199,0.765-0.201,1.08
                         L328.636,422.619z M361.16,214.652l-3.157-4.84c33.984-11.693,60.204-27.676,77.5-40.286l3.781,5.769
                         C430.717,182.855,403.568,204.315,361.16,214.652z"/>
                   </svg>
                   <div class="mt-3">
                      <h5 class="mb-2" style="font-weight: 600;"><a href="#" style="color: #9B5DE5">DAILY CLEANING SCHEDULE</a></h5>
                      <p class="text-secondary">1) Empty all waste bin and rubbish in central position for collection.<br>
                         2) Dust all windows, air-conditioners, furniture and fittings etc.<br>
                         3) Dust and sweep all hard floors and remove spillages by mopping with disinfectant.<br>
                         4) Clean and sanitize all toilet seats with disinfectants.<br>
                         5) Wash and disinfect all toilet and washroom floors.<br>
                         6) Clean and polish all mirrors, doors and glass screens.<br>
                         7) Replenish supplies such as toilet rolls, liquid hand soaps and urinal blocks delivered by Contractor.
                      </p>
                   </div>
                </div>
                <div class="col-md-6 px-4 my-2">
                   <svg height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 450.78 450.78" style="enable-background:new 0 0 450.78 450.78;" xml:space="preserve">
                      <path d="M449.962,173.343L373.338,56.434c-0.521-0.796-1.26-1.427-2.128-1.817c0,0-108.317-49.534-113.373-47.804l-0.604,0.205
                         c-20.534,6.962-43.152,6.962-63.698-0.004l-0.593-0.201c-5.054-1.73-113.374,47.804-113.374,47.804
                         c-0.868,0.391-1.606,1.021-2.128,1.817L0.818,173.343c-1.286,1.962-1.035,4.553,0.602,6.231c1.3,1.334,32.496,32.807,89.373,45.624
                         c2.037,0.453,4.146-0.397,5.287-2.146l14.618-22.415l1.472,226.004c0.015,2.312,1.612,4.312,3.863,4.837
                         c35.771,8.355,72.564,12.533,109.357,12.533c36.793,0,73.586-4.178,109.357-12.533c2.25-0.525,3.848-2.525,3.863-4.837
                         l1.472-226.004l14.618,22.415c1.141,1.748,3.251,2.605,5.287,2.146c56.877-12.817,88.073-44.29,89.373-45.624
                         C450.997,177.896,451.247,175.305,449.962,173.343z M372.299,73.091l57.714,88.058c-14.914,10.884-41.974,28.08-77.646,40.02
                         l-11.193-17.162C358.297,156.778,368.417,99.246,372.299,73.091z M174.936,24.448c4.307,13.438,16.556,29.459,50.454,29.459
                         c33.897,0,46.147-16.021,50.454-29.459c0.174-0.542,0.307-1.09,0.414-1.639l5.578,2.513c-0.086,0.318-0.176,0.636-0.278,0.951
                         c-9.386,29.258-39.216,33.627-56.163,33.627c-16.951,0-46.788-4.368-56.174-33.63c-0.101-0.314-0.191-0.63-0.277-0.947l5.578-2.513
                         C174.629,23.357,174.762,23.905,174.936,24.448z M185.488,17.226c0.419-0.411,1.404-1.193,2.826-1.193
                         c0.426,0,0.891,0.07,1.392,0.241l0.63,0.215c22.605,7.663,47.503,7.663,70.098,0.004l0.642-0.219
                         c2.171-0.741,3.672,0.417,4.217,0.952c0.541,0.53,1.725,1.999,1.029,4.169c-4.787,14.938-18.559,22.512-40.931,22.512
                         c-22.373,0-36.145-7.574-40.932-22.512C183.763,19.225,184.947,17.756,185.488,17.226z M109.606,184.006l-11.193,17.162
                         c-35.672-11.94-62.732-29.136-77.646-40.02l57.714-88.058C82.363,99.246,92.483,156.778,109.606,184.006z M89.62,214.652
                         c-42.411-10.338-69.562-31.8-78.124-39.356l3.781-5.77c17.296,12.609,43.516,28.592,77.499,40.286L89.62,214.652z M328.636,422.619
                         c-67.635,15.191-138.855,15.191-206.492,0l-1.554-238.667c-0.002-0.315-0.086-0.698-0.201-1.08
                         c-0.285-0.938-0.748-1.804-1.299-2.616c-19.039-28.074-29.739-100.95-32.032-118.045l72.689-32.746
                         c4.906,15.168,19.949,40.434,65.649,40.434c45.692,0,60.733-25.268,65.638-40.434l72.689,32.746
                         c-2.294,17.095-12.994,89.971-32.032,118.045c-0.55,0.811-1.014,1.677-1.299,2.616c-0.116,0.382-0.199,0.765-0.201,1.08
                         L328.636,422.619z M361.16,214.652l-3.157-4.84c33.984-11.693,60.204-27.676,77.5-40.286l3.781,5.769
                         C430.717,182.855,403.568,204.315,361.16,214.652z"/>
                   </svg>
                   <div class="mt-3">
                      <h5 class="mb-2" style="font-weight: 600;"><a href="#" style="color: #9B5DE5">WEEKLY CLEANING SCHEDULE</a></h5>
                      <p class="text-secondary">1) Polish all wooden surfaces including desktops, chairs etc with wax polish.<br>
                         2) Damp wipe all tile walls, washrooms etc with germicidal cleaner.<br>
                         3) Sweep and mop all hard floors.<br>
                         4) Clean and dust all Louvre blades, glass panes and screens.<br>
                         5) Clean all gutters thoroughly
                      </p>
                   </div>
                </div>
                <div class="col-md-6 px-4 my-2">
                   <svg height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 450.78 450.78" style="enable-background:new 0 0 450.78 450.78;" xml:space="preserve">
                      <path d="M449.962,173.343L373.338,56.434c-0.521-0.796-1.26-1.427-2.128-1.817c0,0-108.317-49.534-113.373-47.804l-0.604,0.205
                         c-20.534,6.962-43.152,6.962-63.698-0.004l-0.593-0.201c-5.054-1.73-113.374,47.804-113.374,47.804
                         c-0.868,0.391-1.606,1.021-2.128,1.817L0.818,173.343c-1.286,1.962-1.035,4.553,0.602,6.231c1.3,1.334,32.496,32.807,89.373,45.624
                         c2.037,0.453,4.146-0.397,5.287-2.146l14.618-22.415l1.472,226.004c0.015,2.312,1.612,4.312,3.863,4.837
                         c35.771,8.355,72.564,12.533,109.357,12.533c36.793,0,73.586-4.178,109.357-12.533c2.25-0.525,3.848-2.525,3.863-4.837
                         l1.472-226.004l14.618,22.415c1.141,1.748,3.251,2.605,5.287,2.146c56.877-12.817,88.073-44.29,89.373-45.624
                         C450.997,177.896,451.247,175.305,449.962,173.343z M372.299,73.091l57.714,88.058c-14.914,10.884-41.974,28.08-77.646,40.02
                         l-11.193-17.162C358.297,156.778,368.417,99.246,372.299,73.091z M174.936,24.448c4.307,13.438,16.556,29.459,50.454,29.459
                         c33.897,0,46.147-16.021,50.454-29.459c0.174-0.542,0.307-1.09,0.414-1.639l5.578,2.513c-0.086,0.318-0.176,0.636-0.278,0.951
                         c-9.386,29.258-39.216,33.627-56.163,33.627c-16.951,0-46.788-4.368-56.174-33.63c-0.101-0.314-0.191-0.63-0.277-0.947l5.578-2.513
                         C174.629,23.357,174.762,23.905,174.936,24.448z M185.488,17.226c0.419-0.411,1.404-1.193,2.826-1.193
                         c0.426,0,0.891,0.07,1.392,0.241l0.63,0.215c22.605,7.663,47.503,7.663,70.098,0.004l0.642-0.219
                         c2.171-0.741,3.672,0.417,4.217,0.952c0.541,0.53,1.725,1.999,1.029,4.169c-4.787,14.938-18.559,22.512-40.931,22.512
                         c-22.373,0-36.145-7.574-40.932-22.512C183.763,19.225,184.947,17.756,185.488,17.226z M109.606,184.006l-11.193,17.162
                         c-35.672-11.94-62.732-29.136-77.646-40.02l57.714-88.058C82.363,99.246,92.483,156.778,109.606,184.006z M89.62,214.652
                         c-42.411-10.338-69.562-31.8-78.124-39.356l3.781-5.77c17.296,12.609,43.516,28.592,77.499,40.286L89.62,214.652z M328.636,422.619
                         c-67.635,15.191-138.855,15.191-206.492,0l-1.554-238.667c-0.002-0.315-0.086-0.698-0.201-1.08
                         c-0.285-0.938-0.748-1.804-1.299-2.616c-19.039-28.074-29.739-100.95-32.032-118.045l72.689-32.746
                         c4.906,15.168,19.949,40.434,65.649,40.434c45.692,0,60.733-25.268,65.638-40.434l72.689,32.746
                         c-2.294,17.095-12.994,89.971-32.032,118.045c-0.55,0.811-1.014,1.677-1.299,2.616c-0.116,0.382-0.199,0.765-0.201,1.08
                         L328.636,422.619z M361.16,214.652l-3.157-4.84c33.984-11.693,60.204-27.676,77.5-40.286l3.781,5.769
                         C430.717,182.855,403.568,204.315,361.16,214.652z"/>
                   </svg>
                   <div class="mt-3">
                      <h5 class="mb-2" style="font-weight: 600;"><a href="#" style="color: #9B5DE5">MONTHLY CLEANING SCHEDULE</a></h5>
                      <p class="text-secondary">1) Dust High walls, light fittings etc to remove cobwebs and accumulated dust.<br>
                         2) Damp wipe all tiled walls and partitions in toilets with germicidal cleaners.<br>
                         3) Light machine scrub toilet floors and prestigious areas such as entrance, reception, main hall and corridors.<br>
                         4) In-depth cleaning of inaccessible areas in urinals, toilets, windows etc.<br>
                         5) Scrubbing of ceramic and mosaic tiled floors.<br>
                      </p>
                   </div>
                </div>
                <div class="col-md-6 px-4 my-2">
                   <svg height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 450.78 450.78" style="enable-background:new 0 0 450.78 450.78;" xml:space="preserve">
                      <path d="M449.962,173.343L373.338,56.434c-0.521-0.796-1.26-1.427-2.128-1.817c0,0-108.317-49.534-113.373-47.804l-0.604,0.205
                         c-20.534,6.962-43.152,6.962-63.698-0.004l-0.593-0.201c-5.054-1.73-113.374,47.804-113.374,47.804
                         c-0.868,0.391-1.606,1.021-2.128,1.817L0.818,173.343c-1.286,1.962-1.035,4.553,0.602,6.231c1.3,1.334,32.496,32.807,89.373,45.624
                         c2.037,0.453,4.146-0.397,5.287-2.146l14.618-22.415l1.472,226.004c0.015,2.312,1.612,4.312,3.863,4.837
                         c35.771,8.355,72.564,12.533,109.357,12.533c36.793,0,73.586-4.178,109.357-12.533c2.25-0.525,3.848-2.525,3.863-4.837
                         l1.472-226.004l14.618,22.415c1.141,1.748,3.251,2.605,5.287,2.146c56.877-12.817,88.073-44.29,89.373-45.624
                         C450.997,177.896,451.247,175.305,449.962,173.343z M372.299,73.091l57.714,88.058c-14.914,10.884-41.974,28.08-77.646,40.02
                         l-11.193-17.162C358.297,156.778,368.417,99.246,372.299,73.091z M174.936,24.448c4.307,13.438,16.556,29.459,50.454,29.459
                         c33.897,0,46.147-16.021,50.454-29.459c0.174-0.542,0.307-1.09,0.414-1.639l5.578,2.513c-0.086,0.318-0.176,0.636-0.278,0.951
                         c-9.386,29.258-39.216,33.627-56.163,33.627c-16.951,0-46.788-4.368-56.174-33.63c-0.101-0.314-0.191-0.63-0.277-0.947l5.578-2.513
                         C174.629,23.357,174.762,23.905,174.936,24.448z M185.488,17.226c0.419-0.411,1.404-1.193,2.826-1.193
                         c0.426,0,0.891,0.07,1.392,0.241l0.63,0.215c22.605,7.663,47.503,7.663,70.098,0.004l0.642-0.219
                         c2.171-0.741,3.672,0.417,4.217,0.952c0.541,0.53,1.725,1.999,1.029,4.169c-4.787,14.938-18.559,22.512-40.931,22.512
                         c-22.373,0-36.145-7.574-40.932-22.512C183.763,19.225,184.947,17.756,185.488,17.226z M109.606,184.006l-11.193,17.162
                         c-35.672-11.94-62.732-29.136-77.646-40.02l57.714-88.058C82.363,99.246,92.483,156.778,109.606,184.006z M89.62,214.652
                         c-42.411-10.338-69.562-31.8-78.124-39.356l3.781-5.77c17.296,12.609,43.516,28.592,77.499,40.286L89.62,214.652z M328.636,422.619
                         c-67.635,15.191-138.855,15.191-206.492,0l-1.554-238.667c-0.002-0.315-0.086-0.698-0.201-1.08
                         c-0.285-0.938-0.748-1.804-1.299-2.616c-19.039-28.074-29.739-100.95-32.032-118.045l72.689-32.746
                         c4.906,15.168,19.949,40.434,65.649,40.434c45.692,0,60.733-25.268,65.638-40.434l72.689,32.746
                         c-2.294,17.095-12.994,89.971-32.032,118.045c-0.55,0.811-1.014,1.677-1.299,2.616c-0.116,0.382-0.199,0.765-0.201,1.08
                         L328.636,422.619z M361.16,214.652l-3.157-4.84c33.984-11.693,60.204-27.676,77.5-40.286l3.781,5.769
                         C430.717,182.855,403.568,204.315,361.16,214.652z"/>
                   </svg>
                   <div class="mt-3">
                      <h5 class="mb-2" style="font-weight: 600;"><a href="#" style="color: #9B5DE5">QUARTERLY CLEANING SCHEDULE</a></h5>
                      <p class="text-secondary">1) Inaccessible / high rise windows to be thoroughly cleaned.<br>
                         2) Terrazo Floor treatment (scrubbing /stripping and polishing with emulsion polish).
                      </p>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="padding-bottom">
	<div class="container-fluid tagline-sec box-shadowsec" style="background-image: url('assets/images/posts/tagline.jpg'); ">
		<div class="row justify-content-md-center">
			<div class="col-md-8">
				<h1 class="taglin-conte">DON'T JUST CLEAN, GET IT CLEANED THE "UNIK" WAY ...</h1>
				<div class="form-group text-center">
					<button class="btn btn-tag">Request for quote</button>
				</div>
			</div>
		</div>
	</div>
</section>
<section>
	<div class="container my-5">
   <div class="row">
     <div class="col-md-6 p-4 order-2 order-sm-1">
       <h1 class="h2 mb-4" style="font-weight: 600;">CHEMICALS <span style="color: #9B5DE5;">AND MATERIALS</span></h1>
       <p class="text-secondary" style="line-height: 2;">In addition to effective Cleaning services, we also retail and provide international range of chemicals, Air-fresheners, disinfectants etc which are deployed to ensure Client Homes, Offices and Environment not only look Clean but also smell good and totally free from all germs.</p>
       <ul>
       	<li>AIR FRESHENERS AND DEODORIZERS</li>
       	<li>URINAL BLOCKS AND TOILET CLEANERS</li>
       	<li>TELEPHONE SANITIZER</li>
       	<li>FLOOR AND WALL CLEANING POWDER</li>
       	<li>HEAVY DUTY CLEANERS</li>
       	<li>FURNITURE POLISH (WAX)</li>
       	<li>STAINLESS STEEL AND METAL CLEANERS</li>
       	<li>CREAM CLEANERS AND GLASS CLEANERS</li>
       </ul>
     </div>
     <div class="col-md-6 p-0 text-center order-1 order-sm-2">
        <img src="assets/images/mathology-2.jpg" class="w-100" alt="">
     </div>

    <div class="col-md-6 p-0 text-center">
       <img src="assets/images/mathology-1.jpg" class="w-100" alt="">
    </div>
    <div class="col-md-6 p-4">
      <h1 class="h2 mb-4" style="font-weight: 600;">EQUIPMENTS  <span style="color: #9B5DE5;">AND DEVICES</span></h1>
      <p class="text-secondary" style="line-height: 2;">For each project, we utilize modern equipments and technologically advanced Cleaning devices to ensure effectiveness in Service Delivery.</p>
     <ul>
     	<li>WET AND DRY VACUUMS</li>
     	<li>MOP BUCKETS WITH WRINGERS</li>
     	<li>DUST SWEEPERS FOR HARDFLOOR</li>
     	<li>SCOURING PADS, BROOMS, BRUSHES AND DUSTPANS</li>
     	<li>ROTARY FLOOR MACHINE</li>
     	<li>SQUEEGEES, GLOVES, SPRAY BOTTLES,</li>
     	<li>SAFETY SIGNS, EXTENSION LADDER</li>
     	<li>AUTOMATIC SCRUBBER DRIERS ETC</li>
     </ul>
    </div>
   </div>
 </div>

</section>