<section>
   <div class="container-fluid tagline-sec box-shadowsec"
      style="background-image: url('assets/images/banners/guy-mopping-dust-sofa-apartment.jpg'); ">
      <div class="row justify-content-md-center">
         <div class="col-md-8 taglin-conte2">
            <h1 class="text-center" style="font-size:34px">DON'T JUST CLEAN, GET IT CLEANED THE "UNIK" WAY ...</h1>
            <p class="text-center">Established in 2011 to change the face of Commercial cleaning activities; we parade a
               talented team of experienced personnel who handle all projects with precise attention to detail and a
               personal touch</p>
         </div>
      </div>
   </div>
</section>
<section class="we-offer-area text-center bg-gray">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="site-heading text-center">
               <h2>WHAT REALLY MAKES <span>US DIFFERENT?</span></h2>
            </div>
         </div>
      </div>
      <div class="row our-offer-items less-carousel">
         <!-- Single Item -->
         <div class="col-md-3 col-sm-6 equal-height">
            <div class="item">
               <i class="fas fa-dharmachakra"></i>
               <h4>Highest Cleaning Standards</h4>
               <p>
                  Attention to detail is critical in Commercial cleaning and the Unik Janitorial Services team pride
                  ourselves on our impeccable cleaning quality which is always delivered on time.
               </p>
            </div>
         </div>
         <!-- End Single Item -->
         <!-- Single Item -->
         <div class="col-md-3 col-sm-6 equal-height">
            <div class="item">
               <i class="fas fa-id-card-alt"></i>
               <h4>Customer Service</h4>
               <p>
                  From initial scoping through to the actual cleaning and post project follow up; our Customer Service
                  is second to none - with our management team active in every project to ensure we exceed customer
                  satisfaction.
               </p>
            </div>
         </div>
         <!-- End Single Item -->
         <!-- Single Item -->
         <div class="col-md-3 col-sm-6 equal-height">
            <div class="item">
               <i class="fas fa-bug"></i>
               <h4>Health and Safety</h4>
               <p>
                  We are committed to protection ur Clients and staff from the hazards of cleaning. All necessary
                  Occupational Health & Safety rules and regulations are strictly followed; and our teams come equipped
                  with modern cleaning equipments (checked and tagged regularly) and materials.
               </p>
            </div>
         </div>
         <!-- End Single Item -->
         <!-- Single Item -->
         <div class="col-md-3 col-sm-6 equal-height">
            <div class="item">
               <i class="fas fa-users"></i>
               <h4>Professional Cleaning Staff</h4>
               <p>
                  A cleaning job is only as good as the staff that do the work. All UJS Facility Management staff are
               carefully selected, go through a thorough training process and come with police clearances. Most ongoing
               cleaning projects are conducted by the same teams.
               </p>
            </div>
         </div>
         <!-- End Single Item -->
      </div>
   </div>
</section>
<section class="padding-bottom">
   <div class="container-fluid tagline-sec box-shadowsec"
      style="background-image: url('assets/images/posts/tagline.jpg'); ">
      <div class="row justify-content-md-center">
         <div class="col-md-8">
            <h1 class="taglin-conte">DON'T JUST CLEAN, GET IT CLEANED THE "UNIK" WAY ...</h1>
            <div class="form-group text-center">
               <button class="btn btn-tag">Request for quote</button>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="padding-bottom">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="site-heading text-center">
               <h2>Our Philosopy</h2>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-md-4 col-6 ">
            <div class="service-box">
               <div class="service-icon yellow">
                  <div class="front-content">
                     <img class="core_values_img" src="assets/images/core_values/vision_1.png" alt="vision.png">
                     <h3>OUR VISION</h3>
                  </div>
               </div>
               <div class="service-content">
                  <h3>OUR VISION</h3>
                  <p>UJS Facility Management seeks to transform the cleaning industry experience through a culture of
                     excellence, care, quality service and innovation.</p>
               </div>
            </div>
         </div>
         <div class="col-md-4 col-6 ">
            <div class="service-box">
               <div class="service-icon orange">
                  <div class="front-content">
                     <img class="core_values_img" src="assets/images/core_values/mission.png" alt="mission.png">
                     <h3>OUR MISSION</h3>
                  </div>
               </div>
               <div class="service-content">
                  <h3>OUR MISSION</h3>
                  <p>UJS Facility Management will be the leading provider of commercial cleaning services in Ghana by
                     creating a positive impact on the quality of services of the organization we serve, the people we
                     employ. </p>
               </div>
            </div>
         </div>
         <div class="col-md-4 col-6">
            <div class="service-box">
               <div class="service-icon grey">
                  <div class="front-content">
                     <img class="core_values_img" src="assets/images/core_values/core_values_1.png"
                        alt="core_values.png">
                     <h3>OUR VALUES</h3>
                  </div>
               </div>
               <div class="service-content">
                  <h3>OUR VALUES</h3>
                  <p>UJS Facility Management understands the partnership and commitment that it accepts at providing
                     excellent services, the following core values will underpin UJS service delivery to our clients:
                     * Customer service relationship
                     * Integrity
                     * Excellence
                     * Innovation
                     * Responsiveness
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="padding-bottom">
   <div class="container">
      <h2 class="text-center">WHAT DEFINES US
      </h2>
      <div class="botton-border">vvv</div>
      <div class="row">
         <div class="col-md-6 text-about">
            <p class="about-text-heading">CUSTOMER SERVICE IS OUR TOP PRIORITY</p>
            <p class="text-justify">
               From initial scoping through to the actual cleaning and post project follow up UJS Facility Management
               customer service is second to none. Our management team are active in every project to ensure that
               clients are satisfied

            </p>

            <img src="assets/images/Picture1.jpg" class="w-100">
         </div>
         <div class="col-xl-6 text-about">
            <img src="assets/images/Picture2.jpg" class="w-100">
            <p class="about-text-heading mt-5">Attention to detail</p>
            <p class="text-justify">We Always deliver the highest standards of cleaning
               Attention to detail is critical in commercial cleaning and the UJS Facility Management team pride
               ourselves on our impeccable cleaning capabilities which is always delivered on time.
            </p>
         </div>
      </div>
      <div class="row margin-30">
         <div class="col-md-8 text-about d-flex flex-column justify-content-center">
            <p class="about-text-heading">HEALTH AND SAFETY OF OUR CLIENTS AND STAFF IS OF PARAMOUNT IMPORTANCE
            </p>
            <p class="text-justify">
               UJS Facility Management is committed to protecting its clients and staff from the hazards of unhygienic
               environments. We strictly observe all health, and safety rules and regulations and our teams come
               equipped with the leading equipment (checked and tagged regularly) and materials.
            </p>
         </div>
         <div class="col-xl-4">
            <img src="assets/images/Picture3.jpg" class="w-100">
         </div>

      </div>
      <div class="row">
         <div class="col-xl-4">
            <img src="assets/images/Picture4.jpg" class="w-100">
         </div>
         <div class="col-xl-8 text-about d-flex flex-column justify-content-center">
            <!-- <img src="assets/images/Picture5.png" class="middle-img"> -->
            <p class="about-text-heading">ALL STAFF ARE FULLY TRAINED AND COMMITTED CLEANING PROFESSIONALS</p>
            <p class="text-justify">
               A cleaning job is only as good as the staff that do the work. All UJS Facility Management staff are
               carefully selected, go through a thorough training process and come with police clearances. Most ongoing
               cleaning projects are conducted by the same teams. Unik Janitorial Service has one of the highest staff
               retention rates in the industry.
            </p>
         </div>
      </div>
   </div>
</section>