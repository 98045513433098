import { Component, OnInit } from '@angular/core';
import { UserService } from "../service/user.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-searchproduct',
  templateUrl: './searchproduct.component.html',
  styleUrls: ['./searchproduct.component.css']
})
export class SearchproductComponent implements OnInit {

  public searchProducts:any = [];
  public search:any;
  constructor(private service:UserService, private ActivatedRoute:ActivatedRoute, private Router:Router) { }

  ngOnInit(): void {
    this.search = this.ActivatedRoute.snapshot.paramMap.get('value');
    let formData = {'search' : this.search}
    let value = this.ActivatedRoute.snapshot.paramMap.get('value');
    this.service.searchServices(formData).subscribe((success:any)=>{
      this.searchProducts = success;
      console.log(this.searchProducts);
    });
  }

  productdetails = (service_id:any) =>{
    this.Router.navigate(['products-details/' + service_id]);
  }
}
