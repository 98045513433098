<!-- ========================= SECTION SUBSCRIBE  ========================= -->
<!-- <section class="section-subscribe padding-y-lg">
	<div class="container">
	   <p class="pb-2 text-center text-white">Delivering the latest product trends and industry news straight to your inbox</p>
	   <div class="row justify-content-md-center">
		  <div class="col-lg-5 col-md-6">
			 <form class="form-row">
				<div class="col-md-8 col-7">
				   <input class="form-control border-0" placeholder="Your Email" type="email">
				</div>
				<div class="col-md-4 col-5">
				   <button type="submit" class="btn btn-block btn-warning"> <i class="fa fa-envelope"></i> Subscribe </button>
				</div>
			 </form>
			 <small class="form-text text-white-50">We’ll never share your email address with a third-party. </small>
		  </div>

	   </div>
	</div>
 </section> -->
 <!-- ========================= SECTION SUBSCRIBE END// ========================= -->
<footer class="section-footer bg-secondary text-white">
	<div class="container">
		<section class="footer-top  footer-padding">
			<div class="row">
				<aside class="col-md-3 col-12 logo-container">
					<img src="assets/images/logo.png?v=2.0" class="">
					<p class="text-justify">
						"PERFECTION IS ACHIEVED NOT WHEN THERE IS NOTHING MORE TO ADD, BUT WHEN THERE IS NOTHING LEFT TO TAKE AWAY. " </p>
				</aside>
				<aside class="col-md-4 col-12 pl-md-5">
					<article class="mr-md-4">
						<h5 class="title">Contact us</h5>
						
						<ul class="list-icon">
							<li> <i class="icon fa fa-map-marker"> </i>UJS FACILITY MANAGEMENT <br>
								Old Ashongman, Abladjei
								Post Box NT 639, Accra  Newtown,
								Accra, Ghana.
							   </li>
							<li> <i class="icon fa fa-envelope"> </i> info@unikjanitorialservices.com </li>
							<li> <i class="icon fa fa-phone"> </i> Landline : + (233) 0302556038</li>
							<li> <i class="icon fa fa-phone"> </i> Mobile : +(233) 0547555620, 0262761515</li>
						</ul>
					</article>
				</aside>
				<aside class="col-md-2 col-12 pl-md-5">
					<h5 class="title">Information</h5>
					<ul class="list-unstyled">
						<li> <a routerLink="about">About us</a></li>
						<li> <a routerLink="team">Our Team</a></li>
						<li> <a routerLink="methodology">Methodology</a></li>
						<li> <a routerLink="contact-us">Contact Us</a></li>
					</ul>
				</aside>
				<!-- <aside class="col-md col-6">
					<h5 class="title">Quick Link</h5>
					<ul class="list-unstyled">
						<li> <a href="#">Home Cleaning</a></li>
						<li> <a href="#">Home Cleaning</a></li>
						<li> <a href="#">Home Cleaning</a></li>
						<li> <a href="#">Home Cleaning</a></li>
						<li> <a href="#">Home Cleaning</a></li>
					</ul>
				</aside> -->
				<aside class="col-md-3 col-12">
					<!-- <p class="text-white-50 mb-2">Follow us on social media</p> -->
					<h5 class="title">Follow us on social media</h5>
					<div class="mb-5">
						<a href="https://www.facebook.com/share/jt7r8hCuEGybvHDa/?mibextid=qi2Omg" class="btn btn-icon btn-outline-light" target="_blank"><i class="fab fa-facebook-f"></i></a>

						<a href="https://twitter.com/FacilityUjs" class="btn btn-icon btn-outline-light"><i class="fab fa-twitter"></i></a>

						<a href="https://www.instagram.com/ujs_gh?igsh=anY1bzB4OGNsYnRs" class="btn btn-icon btn-outline-light" target="_blank"><i class="fab fa-instagram"></i></a>

						<a href="https://www.linkedin.com/company/ujs-facility-management/" target="_blank" class="btn btn-icon btn-outline-light"><i class="fab fa-linkedin"></i></a>

						<!-- <a href="#" target="_blank" class="btn btn-icon btn-outline-light"><i class="fab fa-youtube"></i></a> -->
					</div>
					<h5 class="title">Newsletter</h5>
					<p class="text-justify">Subscribe to our newsletter for the latest updates, insights, and exclusive offers delivered straight to your inbox.</p>

					<form class="subscribe-form">
						<input type="text" placeholder="Email" class="border-0 form-control" name="">
						<button class="btn ml-2 btn-warning"> Subscribe</button>
					</form>

					

				</aside>
			</div> <!-- row.// -->
		</section>	<!-- footer-top.// -->
	</div><!-- //container -->
</footer>
