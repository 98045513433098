import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 

@Injectable({
  providedIn: 'root'
})
export class CartserviceService {

  public cartItemList:any = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
  public check:any = 0;
  public productList:any = new BehaviorSubject<any>([]); 
  
  constructor() {
  }

  getProduct = () =>{
    // localStorage.clear();
    this.productList.next(this.cartItemList);
    return this.productList.asObservable();
  }

  addToCart = (product:any) =>{
    let totalUpdatedQty = 0;
    if(this.cartItemList.length != 0){ // Here we are checking if the cart is empty !
      this.cartItemList.map((data:any, id:any)=>{
        if(data.item_name == product.item_name){ // We have list of items in a single product, Check the item is in Pro ID !
           totalUpdatedQty = parseInt(data.qty)+parseInt(product.qty);
          //  console.log(product.max_qty, totalUpdatedQty);return;
          if(product.max_qty >= totalUpdatedQty){
            this.cartItemList[id].qty = this.cartItemList[id].qty + parseInt(product.qty);
            this.cartItemList[id].subtotal = product.current_price * this.cartItemList[id].qty;
            this.productList.next(this.cartItemList);
            this.getTotalPrice();
            localStorage.setItem('cart',JSON.stringify(this.cartItemList));
            Swal.fire('Cart Quantity Updated');
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Stock is not available !',
              footer: ''
            })
          }
        }
        else{
          let matchedProducts = 0;
          this.cartItemList.map((subItem:any)=>{ // Here checking if the item name from product is matched with any of the product of cart !
            if(product.item_name == subItem.item_name){
              matchedProducts++;
            }
          });
          if(matchedProducts == 0 ){
            this.cartItemList.push(product);
            this.productList.next(this.cartItemList);
            this.getTotalPrice();
            localStorage.setItem('cart',JSON.stringify(this.cartItemList));
            Swal.fire('Product added into cart');
          }
        }
      });
    }
    else{
      this.cartItemList.push(product);
      this.productList.next(this.cartItemList);
      this.getTotalPrice();
      localStorage.setItem('cart',JSON.stringify(this.cartItemList));
      Swal.fire('Product added into cart');
    } 
  }

  getTotalPrice = () : number =>{
    let grandTotal = 0;
    this.cartItemList.map((a:any)=>{
      grandTotal += parseInt(a.total); 
    });
    return grandTotal;
  }

  removeCart = (product_id:any, item_name:any) =>{
    Swal.fire({  
      title: 'Are you sure want to remove?',  
      text: 'This item will be removed from cart!',  
      icon: 'warning',  
      showCancelButton: true,  
      confirmButtonText: 'Yes, remove it!',  
      cancelButtonText: 'No, keep it'  
    }).then((result) => {  
      if (result.value) {
        
        this.cartItemList.map((item:any, id:any)=>{
          if(item.product_id == product_id && item.item_name == item_name)
          {
            this.cartItemList.splice(id,1);
            this.productList.next(this.cartItemList);
            this.getTotalPrice();
            localStorage.setItem('cart',JSON.stringify(this.cartItemList));
          }
      });
        
        Swal.fire(  
          'Removed!',  
          'Your cart Item has been removed.',  
          'success'  
        )  
      } else if (result.dismiss === Swal.DismissReason.cancel) {  
        Swal.fire(  
          'Cancelled',  
          '',  
          'error'  
        )  
      }  
    })  
  }

  removeAllCart = () =>{
    this.cartItemList = [];
    this.productList.next(this.cartItemList);
  }

  increaseCart = (product_id:any, item_name:any, max_qty:any) =>{
    this.cartItemList.map((item:any, id:any)=>{
        if(item.product_id == product_id && item.item_name == item_name && parseInt(max_qty)-1 >= item.qty)
        {
          this.cartItemList[id].qty++;
          this.cartItemList[id].total = this.cartItemList[id].qty * item.current_price;
          this.productList.next(this.cartItemList);
          this.getTotalPrice();
          localStorage.setItem('cart',JSON.stringify(this.cartItemList));
        }
    });
  }

  decreaseCart = (product_id:any, item_name:any, min_qty:any) =>{
    this.cartItemList.map((item:any, id:any)=>{
        if(item.product_id == product_id && item.item_name == item_name && min_qty < item.qty)
        {
          this.cartItemList[id].qty--;
          this.cartItemList[id].total = this.cartItemList[id].qty * item.current_price;
          this.productList.next(this.cartItemList);
          this.getTotalPrice();
          localStorage.setItem('cart',JSON.stringify(this.cartItemList));
        }
    });
  }
}
