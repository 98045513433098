<section class="py-3 bg-light mb-30">
    <div class="container">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item">{{ category.category_name | titlecase }}</li>
          <li class="breadcrumb-item">{{ subcategory.subcategory_name | titlecase  }}</li>
      </ol>
    </div>
  </section>
  <section class="padding-bottom">
    <div class="container">
      <div class="row">
        <div *ngIf='services.length < 1' class="not-found">
          No Products Found
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-6" *ngFor="let service of services">
          <div class="card card-product-grid bord-1">
            <a href="javascript:void(0)" (click)='productdetails(service.id)' class="img-wrap-2"> <img src="{{ service.service_image }}"> </a>
            <figcaption class="info-wrap text-center">
              <div class="product-name">
                <a href="javascript:void(0)" (click)='productdetails(service.id)' class="">{{ service.service_name }}</a>
              </div>
              <!-- price.// -->
            </figcaption>
          </div>
        </div>
        <!-- col.// -->
      </div>
      <!-- row.// -->
    </div>
  </section>
