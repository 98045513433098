import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public api = environment.apiLink;
  constructor(private http:HttpClient) { }

  getAllCategories = ()=>{
    let apiUrl = `${this.api}get-categories`;
    return this.http.get(apiUrl);
  }

  getServicesByOneSubcategory = ()=>{
    let apiUrl = `${this.api}get-services-by-singleSubcategory`;
    return this.http.get(apiUrl);
  }

  getServices = ()=>{
    let apiUrl = `${this.api}get-services`;
    return this.http.get(apiUrl);
  }

  getServicesByCategory = (cat_id:any) =>{
    let apiUrl = `${this.api}get-services-by-category/${cat_id}`;
    return this.http.get(apiUrl);
  }

  getSubcategories = (cat_id:any) =>{
    let apiUrl = `${this.api}get-subcategories/${cat_id}`;
    return this.http.get(apiUrl);
  }

  getServiceById = (service_id:any) =>{
    let apiUrl = `${this.api}get-services-by-id/${service_id}`;
    return this.http.get(apiUrl);
  }

  getPriceById = (price_id:any) =>{
    let apiUrl = `${this.api}getprice-info-by-id/${price_id}`;
    return this.http.get(apiUrl);
  }

  searchServices = (search:any) =>{
    let apiUrl = `${this.api}search-products`;
    return this.http.post(apiUrl, search);
  }

  userResigter = (formValue:any) =>{
    let apiUrl = `${this.api}user-register`;
    return this.http.post(apiUrl,formValue);
  } 

  userLogin = (formValue:any) =>{
    let apiUrl = `${this.api}user-login`;
    return this.http.post(apiUrl,formValue);
  } 

  editUser = (formValue:any) =>{
    let apiUrl = `${this.api}edit-user/${formValue.id}`;
    return this.http.post(apiUrl,formValue);
  }

  singleUserDetail = (id:any) =>{
    let apiUrl = `${this.api}single-user-detail/${id}`;
    return this.http.get(apiUrl);
  }

  contactUs = (formValue:any)=>{
    let apiUrl = `${this.api}contact-us`;
    return this.http.post(apiUrl,formValue);
  }

  getServiceBySubcategory = (subcat_id:any) =>{
    let apiUrl = `${this.api}get-services-by-subcategory/${subcat_id}`;
    return this.http.get(apiUrl);
  }
}
