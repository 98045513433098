import { Component, OnInit } from '@angular/core';
import { CartserviceService } from "../service/cartservice.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  public cart:any;
  public total_price:any;
  constructor(private CartserviceService:CartserviceService, private Router:Router) { }

  ngOnInit(): void {
    this.CartserviceService.getProduct().subscribe((cartData:any)=>{
      this.cart = cartData;
      this.total_price = this.CartserviceService.getTotalPrice();
    });
  }

  increaseCart = (product_id:any, item_name:any, max_qty:any) =>{
    this.CartserviceService.increaseCart(product_id, item_name, max_qty);
  }

  decreaseCart = (product_id:any, item_name:any, min_qty:any) =>{
    this.CartserviceService.decreaseCart(product_id, item_name, min_qty);
  }
  
  removeCartItem = (product_id:any, item_name:any) =>{
    this.CartserviceService.removeCart(product_id, item_name);
  }

  productDetails = (product_id:number) =>{
    this.Router.navigate(['products-details/'+product_id]);
  }

  makePurchase = () =>{
    console.log(JSON.parse(localStorage.getItem('cart')));
  }
}
