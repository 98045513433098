<!-- ========================= SECTION MAIN  ========================= -->
<section class="section-intro padding-bottom">
   <div class="container-fluid pad-0">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
         <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
         </ol>
         <div class="carousel-inner">
            <div class="carousel-item active">
               <img src="assets/images/banners/slide-lg-2.jpg" alt="...">
               <div class="carousel-caption d-md-block">
                  <h5>KEEP YOUR HOME, OFFICE AND ENVIRONMENT CLEAN THE UNIK WAY ...</h5>
                  <a href="#" class="btn">ORDER OUR SERVICES TODAY</a>
               </div>
            </div>
            <div class="carousel-item">
               <img src="assets/images/banners/slide-lg-1.jpg" alt="...">
               <div class="carousel-caption d-md-block">
                  <h5>KEEP YOUR HOME, OFFICE AND ENVIRONMENT CLEAN THE UNIK WAY ...</h5>
                  <a href="#" class="btn">ORDER OUR SERVICES TODAY</a>
               </div>
            </div>
            <div class="carousel-item">
               <img src="assets/images/banners/slide-lg-3.jpg" alt="...">
               <div class="carousel-caption d-md-block">
                  <h5>KEEP YOUR HOME, OFFICE AND ENVIRONMENT CLEAN THE UNIK WAY ...</h5>
                  <a href="#" class="btn">ORDER OUR SERVICES TODAY</a>
               </div>
            </div>
         </div>
         <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
         </a>
         <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
         </a>
      </div>
   </div>
</section>
<!-- =============== SECTION DEAL =============== -->
<section>
   <div class="container">
      <div class="card card-deal">
         <div class="row no-gutters items-wrap">
            <div class="col-md-2 col-6" *ngFor="let item of categories">
               <figure class="card-product-grid card-sm">
                  <a href="javascript:void(0)" (click)="getSubcategory(item.id)" class="img-wrap">
                     <img src="{{ item.category_image }}">
                  </a>
                  <div class="text-wrap p-3">
                     <a href="javascript:void(0)" (click)="getSubcategory(item.id)" class="title">{{ item.category_name
                        | slice:0:20 }}</a>
                  </div>
               </figure>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="padding-bottom">
   <div class="container">
      <header class="section-heading heading-line">
         <h4 class="title-section">Popular Services</h4>
      </header>
      <div class="slide-container">
         <div class="services-slider">
             <div class="slider-wrapper">
                 <div class="services-slide" *ngFor="let service of service_images">
                     <div class="img-container">
                         <img [src]='service.img' alt="" class="services-image">
                         <div class="services-info">
                             <div class="services-title">{{ service.title }}</div>
                             <!-- <div class="services-description">A burger tossed with some coleslaw and a hint of mint. </div>
                             <button class="add-to-cart">+ Add to Cart</button> -->
                         </div>
                     </div>
                 </div>
             </div>
            
         </div>
     </div>
      <!-- <div class="row">
         <carousel autoplay="true" autoplayInterval="2000" loop="true">
            <div class="carousel-cell">
               <img src="assets/images/prof-1.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-2.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-3.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-1.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-2.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-3.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-1.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-2.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-3.jpg" cellWidth="100%">
            </div>
            <div class="carousel-cell">
               <img src="assets/images/prof-2.jpg" cellWidth="100%">
            </div>
         </carousel>
      </div> -->
      <!-- row.// -->
   </div>
</section>
<!-- =============== SECTION SERVICES .//END =============== -->
<!-- <section class="padding-bottom">
   <div class="container">
      <header class="section-heading heading-line">
         <h4 class="title-section">{{ servicesByOneSubcategory.subcategory_name }}</h4>
      </header>
      <div class="card card-home-category" style="border:1px solid #ebecf0;">
         <div class="row no-gutters">
            <div class="col-md-3">
               <div class="home-category-banner bg-light-orange">
                  <img src="assets/images/HC_v8.jpg" class="w-100">
               </div>
            </div>
            <div class="col-md-9">
               <ul class="row no-gutters bordered-cols">
                  <li class="col-6 col-lg-3 col-md-4"
                     *ngFor="let item of servicesByOneSubcategory.get_all_service_by_one_subcategory">
                     <a (click)="redirectToProduct(item.id)" class="item" style="cursor: pointer;">
                        <div class="card-body">
                           <img class="img-sm img-service" src="{{ item.service_image }}">
                           <h6 class="title service-heading text-center">{{ item.service_name }}</h6>
                        </div>
                     </a>
                  </li>
                  <li class="col-6 col-lg-3 col-md-4">
                     <a href="#" class="item">
                        <div class="card-body">
                           <h6 class="title service-heading">
                              <a href="javascript:void" class="view-all" (click)="viewallproduct(2)">View All</a>
                           </h6>
                           <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Guanjou, China</p>
                        </div>
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</section> -->
<!-- =============== SECTION 1 END =============== -->
<!-- =============== SECTION DEAL // END =============== -->
<section class="padding-bottom">
   <div class="container-fluid tagline-sec box-shadowsec"
      style="background-image: url('assets/images/posts/tagline.jpg'); ">
      <div class="row justify-content-md-center">
         <div class="col-md-8">
            <h1 class="taglin-conte">DON'T JUST CLEAN, GET IT CLEANED THE "UNIK" WAY ...</h1>
            <div class="form-group text-center">
               <button class="btn btn-tag">Request for quote</button>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="padding-bottom">
   <div class="container">
      <h2 class="text-center">Who We Are</h2>
      <div class="botton-border">vvv</div>
      <div class="row">
         <div class="col-md-6 text-about">
            <p>UJS Facility Management was formed in 2011 to provide a truly independent and personal service.
               In an industry where knowledge and experience counts, our talented team of key personnel enjoy a wealth
               of experience, providing in-depth understanding of your particular needs and requirements, which are
               unique to every building.
               To this end, UJS Facility Management has it’s own team for each contract to provide regular Cleaning and
               servicing.
            </p>
            <p>Our continued growth has been achieved by our unparalled commitment to contract supervision and
               continuous detailed inspections, combined with regular client and staff contact.
               All our Directors as shareholders have an unrivalled commitment to the success of the Company. 
               Professionalism in their field, a personal service and determination to provide a value for money service
               are the hallmark of UJS Facility Management.In addition to our core cleaning services, we also provide a full range of support services.</p>
            <!-- <p>In addition to our core cleaning services, we also provide a full range of support services.
               <br><strong>What really makes us so different?</strong><br>
               Our staff with their professional approach, are each individually inducted, fully trained and highly
               motivated to ensure the stringent standards that clients expect from a company fully
            </p>
            <a routerLink ="/about" class="btn btn-tag">Read More</a> -->
         </div>
         <div class="col-xl-6">
            <img src="assets/images/about.png" class="w-100">
         </div>
      </div>
   </div>
</section>

<section class="padding-bottom mt-5">
   <div class="container">
      <h2 class="text-center">What really makes us so different?</h2>
      <div class="botton-border">vvv</div>
      <div class="row">
         <div class="col-md-12 quote-section">
            <p class="quote-text">
               Our staff with their professional approach, are each individually inducted,<br> fully trained and highly
               motivated to ensure the stringent standards that clients expect from a company fully
            </p>
            <a routerLink ="/about" class="btn btn-tag">Read More</a>
         </div>
      </div>
   </div>
</section>

<section class="padding-y padding-bottom" style="background-color:#01567d; padding:50px 0px;">
   <div class="container">
      <div class="row">
         <div class="col-md-3 col-6">
            <div class="counterup">
               <h2 class="counter-text">{{ year }}</h2>
               <h3>every day of the year</h3>
            </div>
         </div>
         <div class="col-md-3 col-6">
            <div class="counterup">
               <h2 class="counter-text">25</h2>
               <h3>COMMERCIAL clients</h3>
            </div>
         </div>
         <div class="col-md-3 col-6">
            <div class="counterup">
               <h2 class="counter-text">92</h2>
               <h3>PROFESSIONAL CLEANING</h3>
            </div>
         </div>
         <div class="col-md-3 col-6">
            <div class="counterup">
               <h2 class="counter-text">6</h2>
               <h3>cLEANING SCHEDULES a day</h3>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="padding-bottom padding-y">
   <div class="container">
      <div class="row justify-content-md-center padding-bottom">
         <div class="col-md-3 col-6">
            <img src="assets/images/guarntee.png" class="img-center img-guarntee">
         </div>
         <div class="col-md-3 col-6">
            <h4 class="grt-title">HAPPINESS GUARANTEE</h4>
            <p class="grt-paragrapg">Because your happiness is our goal!</p>
         </div>
      </div>
      <div class="row">
         <div class="col-md-3 col-6">
            <img src="assets/images/garrntee-1.png" class="img-center">
            <p class="text-center grt-point">Verified Professionals</p>
         </div>
         <div class="col-md-3 col-6">
            <img src="assets/images/shiels.png" class="img-center">
            <p class="text-center grt-point">Insured Work</p>
         </div>
         <div class="col-md-3 col-6">
            <img src="assets/images/re-work.png" class="img-center">
            <p class="text-center grt-point">Re-work Assurance</p>
         </div>
         <div class="col-md-3 col-6">
            <img src="assets/images/customer-services.png" class="img-center">
            <p class="text-center grt-point">Verified Professionals</p>
         </div>
      </div>
   </div>
</section>
<section class="padding-y" style="background-color:#01567d; padding:30px 0px;">
   <div class="container-fluid">
      <div class="row justify-content-md-center">
         <div class="col-md-6 taglin-conte2">
            <h1>KEEP YOUR OFFICE, HOME AND ENVIRONMENT CLEAN THE "UNIK" WAY</h1>
            <p>ORDER OUR SERVICES TODAY:</p>
            <p>CALL 030.341.2003 / 056.021.4008</p>
            <p>OR</p>
            <div class="form-group text-center">
               <button class="btn btn-tag">DROP US A LINE</button>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="padding-bottom d-md-block d-none">
   <div class="container">
      <div class="row pading-client">
         <div class="col-md-12">
            <h2 class="text-center">OUR CURRENT CORPORATE CLIENTS</h2>
            <div class="botton-border"></div>
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/afronation.png" class="img-center client-img ">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/c_creative.png" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/davenue.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/diamond_capital.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/EmpirE.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/Equity_health_insurance.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/FBNBank_ghana.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/ghana_uk.png" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/ieng.png" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/joshob.png" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/marketing_360.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3 col-4">
            <img src="assets/images/clients/nsano.jpg" class="img-center client-img">
         </div>
      </div>
      <!-- row // -->
   </div>
</section>
<section class="padding-bottom d-block">
   <div class="container">
      <div class="row pading-client">
         <div class="col-md-12">
            <h2 class="text-center">OUR FOOT PRINT OVER THE YEARS</h2>
            <div class="botton-border"></div>
         </div>
         <div class="col-md-3">
            <img src="assets/images/footprint/cosmopolitan.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3">
            <img src="assets/images/footprint/cv.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3">
            <img src="assets/images/footprint/dfl.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3">
            <img src="assets/images/footprint/microfinance.jpg" class="img-center client-img">
         </div>
         <div class="col-md-3">
            <img src="assets/images/footprint/wwbg.jpg" class="img-center client-img">
         </div>
      </div>
      <!-- row // -->
   </div>
</section>