<section class="section-pagetop bg-gray">
    <div class="container">
        <h2 class="title-page">My account</h2>
    </div> <!-- container //  -->
    </section>
    <!-- ========================= SECTION PAGETOP END// ========================= -->
        
    <!-- ========================= SECTION CONTENT ========================= -->
    <section class="section-content padding-y">
    <div class="container">
    
    <div class="row">
        <aside class="col-md-3">
            <nav class="list-group">
                <a class="list-group-item active" href="page-profile-main.html"> Account overview  </a>
                <a class="list-group-item" href="page-profile-address.html"> My Address </a>
                <a class="list-group-item" href="page-profile-orders.html"> My Orders </a>
                <a class="list-group-item" href="page-profile-wishlist.html"> My wishlist </a>
                <a class="list-group-item" href="page-profile-seller.html"> My Selling Items </a>
                <a class="list-group-item" href="page-profile-setting.html"> Settings </a>
                <a class="list-group-item" href="javascript::void(0)" (click)="logout()"> Log out </a>
            </nav>
        </aside> <!-- col.// -->
        <main class="col-md-9">
    
            <article class="card mb-3">
                <div class="card-body">
                    
                    <figure class="icontext">
                            <div class="icon">
                                <img class="rounded-circle img-sm border" src="assets/images/avatars/avatar3.jpg">
                            </div>
                            <div class="text">
                                <strong> Mr. {{ userDetails.data.name }} </strong> <br> 
                                <p class="mb-2"> {{ userDetails.data.email }} </p> 
                                <a href="#" class="btn btn-light btn-sm" data-toggle="modal" data-target="#exampleModalCenter">Edit</a>
                            </div>
                    </figure>
                    <hr>
                    <p>
                        <i class="fa fa-map-marker text-muted"></i> &nbsp; My address:  
                         <br>
                        {{ userDetails.data.city }}, {{ userDetails.data.country }}
                        <a href="javascript:void(0)" data-toggle="modal" data-target="#exampleModalCenter" class="btn-link"> Edit</a>
                    </p>
                    <article class="card-group card-stat">
                        <figure class="card bg">
                            <div class="p-3">
                                 <h4 class="title">38</h4>
                                <span>Orders</span>
                            </div>
                        </figure>
                        <figure class="card bg">
                            <div class="p-3">
                                 <h4 class="title">5</h4>
                                <span>Wishlists</span>
                            </div>
                        </figure>
                        <figure class="card bg">
                            <div class="p-3">
                                 <h4 class="title">12</h4>
                                <span>Awaiting delivery</span>
                            </div>
                        </figure>
                        <figure class="card bg">
                            <div class="p-3">
                                 <h4 class="title">50</h4>
                                <span>Delivered items</span>
                            </div>
                        </figure>
                    </article>
                    
    
                </div> <!-- card-body .// -->
            </article> <!-- card.// -->
    
    
        </main> <!-- col.// -->
    </div>
    
    </div> <!-- container .//  -->
    </section>

    
 <!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
    Launch demo modal
  </button>
   -->
  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Edit Profile</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form" (ngSubmit)="getUser()" > 
                <div class="form-group">
                    <label for="exampleInputPassword1">Name</label>
                    <input type="hidden" [(ngModel)]="userDetails.data.id" formControlName="id">
                    <input type="text" class="form-control" [(ngModel)]="userDetails.data.name" formControlName="name" placeholder="Name" >
                  </div>
                  <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
                        <div *ngIf="f.name.errors.required">Name is required.</div>
                    </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Mobile</label>
                    <input type="text" class="form-control" [(ngModel)]="userDetails.data.mobile" formControlName="mobile" placeholder="Mobile">
                  </div>
                  <div *ngIf="f.mobile.touched && f.mobile.invalid" class="alert alert-danger">
                    <div *ngIf="f.mobile.errors.required">Mobile is required.</div>
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input type="email" class="form-control" [(ngModel)]="userDetails.data.email" formControlName="email"  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                </div>

                <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                    <div *ngIf="f.email.errors.required">Email is required.</div>
                </div>

                <div class="form-group">
                    <label for="exampleInputEmail1">City</label>
                    <input type="email" class="form-control" [(ngModel)]="userDetails.data.city" formControlName="city" id="exampleInputEmail1" aria-describedby="emailHelp" >
                  </div>
                  <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger">
                    <div *ngIf="f.city.errors.required">City is required.</div>
                </div>

                  <div class="form-group">
                    <label for="exampleInputEmail1">Country</label>
                    <input type="email" class="form-control" [(ngModel)]="userDetails.data.country" formControlName="country" id="exampleInputEmail1" aria-describedby="emailHelp">
                  </div>

                  <div *ngIf="f.country.touched && f.country.invalid" class="alert alert-danger">
                    <div *ngIf="f.country.errors.required">Country is required.</div>
                </div>

                  <div class="form-group">
                    <label for="exampleInputEmail1">Gender</label>
                    <select class="form-control" formControlName="gender" [(ngModel)]="userDetails.data.gender">
                        <option value="">Select</option>
                        <option value="{{gen.key}}" *ngFor="let gen of gender | keyvalue">{{ gen.value }}</option>
                    </select>
                  </div>

                  <div *ngIf="f.gender.touched && f.gender.invalid" class="alert alert-danger">
                    <div *ngIf="f.gender.errors.required">Gender is required.</div>
                </div>

                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input type="password" class="form-control" formControlName="password" id="exampleInputPassword1" placeholder="Password">
                  <small class="form-text text-muted">Your password is encrypted cannot be shown here, You can change if you want.</small>
                </div>

                <!-- <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                    <div *ngIf="f.password.errors.required">Password is required.</div>
                </div> -->

                <button type="submit" [disabled]="form.invalid" class="btn btn-primary">Update</button>
              </form>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>