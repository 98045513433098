import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { UserService } from "../service/user.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  
  form: FormGroup;
  public user:any;
  public userDetails:any;
  public gender:any;
  public selected:any = 'selected';
  public editForm:any;
  constructor(private Router:Router, private UserService:UserService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.UserService.singleUserDetail(this.user.id).subscribe((res)=>{
      this.userDetails = res;
    });
    this.gender = {
      0 : 'female',
      1 : 'male'
    };

    this.form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      mobile: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      password: new FormControl(''),
      gender: new FormControl('', Validators.required)
    });
    
  }

  get f(){
    return this.form.controls;
  }

  logout = () =>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You will be logged out!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log me out!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
        localStorage.removeItem('user');
        Swal.fire('You have been Logged out !');
        this.Router.navigate(['/']);
      }
    })
  }

  getUser = () =>{
    this.UserService.editUser(this.form.value).subscribe((success)=>{
      console.log(success);
      document.getElementById("exampleModalCenter").click();
      Swal.fire('User Updated.');
    });
  }
}
