<section class="py-3 bg-light mb-30">
    <div class="container">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Cart</a></li>
      </ol>
    </div>
  </section>
<div class="empty-cart" *ngIf="cart.length == 0">
    <p>Your Cart is empty</p>
    <a routerLink="" class="btn btn-primary">Continue Shopping</a>
</div>
<section class="section-content padding-y" style="background-color: #f6f7f9;" *ngIf="cart.length != 0">
    <div class="container">
    
    <div class="row">
        <main class="col-md-9">
    <div class="card" style=" background-color: #fff;
        border: 1px solid #ebecf0;">
    
    <table class="table table-borderless table-shopping-cart">
    <thead class="text-muted">
    <tr class="small text-uppercase">
      <th scope="col">Product</th>
      <th scope="col">Quantity</th>
      <th scope="col">Price</th>
      <th scope="col">Total</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let cartItem of cart">
        <td>
            <figure class="itemside">
                <div class="aside"><img src="{{ cartItem.product_image }}" class="img-sm"></div>
                <figcaption class="info">
                    <a href="javascript:void(0)" (click)="productDetails(cartItem.product_id)" class="title text-dark">{{ cartItem.product_name }}</a>
                    <p class="text-muted small">{{ cartItem.item_name }}</p>
                </figcaption>
            </figure>
        </td>
        <td>  
            <div class="input-group mb-3 input-spinner">
                <div class="input-group-prepend">
                    <button class="btn btn-light" (click)="decreaseCart(cartItem.product_id, cartItem.item_name, cartItem.min_qty)" type="button" id="button-minus"> &minus; </button>
                </div>
                <input type="text" class="form-control"  value="{{ cartItem.qty }}">
                <div class="input-group-append">
                   <button class="btn btn-light" (click)="increaseCart(cartItem.product_id, cartItem.item_name, cartItem.max_qty)" type="button" id="button-plus"> + </button>
                </div>
             </div>
        </td>
        <td>  
            <div class="price-wrap"> 
                <var class="price">${{ cartItem.current_price }}</var> 
            </div> <!-- price-wrap .// -->
        </td>
        <td> 
            <div class="price-wrap"> 
                <var class="price">${{ cartItem.total }}</var> 
            </div> <!-- price-wrap .// -->
        </td>
        <td> 
        <!-- <a data-original-title="Save to Wishlist" title="" href="#" class="btn btn-light wishlist-btn" data-toggle="tooltip"> <i class="fa fa-heart"></i></a>  -->
        <button (click)="removeCartItem(cartItem.product_id, cartItem.item_name)" class="btn btn-light"> Remove</button>
        </td>
    </tr>
    </tbody>
    </table>

    <div class="card-body border-top">
        <a href="javascript:void(0)" (click)="makePurchase()" class="btn btn-primary float-md-right"> Make Purchase <i class="fa fa-chevron-right"></i> </a>
        <a routerLink="" class="btn btn-light"> <i class="fa fa-chevron-left"></i> Continue shopping </a>
    </div>	
    </div> <!-- card.// -->
    
    <div class="alert alert-success mt-3">
        <p class="icontext"><i class="icon text-success fa fa-truck"></i> Free Delivery within 1-2 weeks</p>
    </div>
    
        </main> <!-- col.// -->
        <aside class="col-md-3">
            <div class="card mb-3">
                <div class="card-body" style="Color:#000;">
                <form>
                    <div class="form-group">
                        <label>Have coupon?</label>
                        <div class="input-group">
                            <input type="text" class="form-control" name="" placeholder="Coupon code">
                            <span class="input-group-append"> 
                                <button class="btn btn-primary">Apply</button>
                            </span>
                        </div>
                    </div>
                </form>
                </div> <!-- card-body.// -->
            </div>  <!-- card .// -->
            <div class="card">
                <div class="card-body" style="color: #000;">
                        <dl class="dlist-align">
                          <dt>Total price:</dt>
                          <dd class="text-right">USD {{ total_price }}</dd>
                        </dl>
                        <dl class="dlist-align">
                          <dt>Discount:</dt>
                          <dd class="text-right">USD 0</dd>
                        </dl>
                        <dl class="dlist-align">
                          <dt>Total:</dt>
                          <dd class="text-right  h5"><strong>${{ total_price }}</strong></dd>
                        </dl>
                        <hr>
                        <p class="text-center mb-3">
                            <img src="assets/images/misc/payments.png" height="26">
                        </p>
                        
                </div> <!-- card-body.// -->
            </div>  <!-- card .// -->
        </aside> <!-- col.// -->
    </div>
    
    </div> <!-- container .//  -->
    </section>

    <section class="section-name border-top padding-y" style="padding-top:30px;">
        <div class="container">
        <h6>Payment and refund policy</h6>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        
        </div><!-- container // -->
        </section>