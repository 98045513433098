<section class="py-3 bg-light mb-30">
    <div class="container">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">{{ category.category_name | titlecase  }}</a></li>
      </ol>
    </div>
  </section>
  <section class="padding-bottom">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
            <div *ngIf='subcategories.length < 1' class="not-found ml-4">
                Subcategoris Not Found..
              </div>
        </div>
        <div class="col-md-3 col-6" *ngFor="let subcategory of subcategories">
          <div class="card card-product-grid bord-1">
            <a href="javascript:void(0)" (click)="redirectToService(subcategory.id)" class="img-wrap-2"> <img src="{{ subcategory.subcategory_image }}"> </a>
            <figcaption class="info-wrap text-center">
              <div class="product-name">
                <a href="javascript:void(0)" (click)="redirectToService(subcategory.id)" class="">{{ subcategory.subcategory_name }}</a>
              </div>
              <!-- price.// -->
            </figcaption>
          </div>
        </div>
        <!-- col.// -->
      </div>
      <!-- row.// -->
    </div>
  </section>
