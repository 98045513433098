import { Component, OnInit } from '@angular/core';
import { UserService} from "../service/user.service";
import { Router } from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public response:any;
  constructor(private UserService:UserService, private Router:Router) { }

  ngOnInit(): void {
  }

  login = (formValue:any) =>{
      this.UserService.userLogin(formValue).subscribe((success)=>{
        this.response = success;
        if(this.response.code == 200){
          localStorage.setItem('user',JSON.stringify(this.response.data));
          Swal.fire('Logged in successfully');
          this.Router.navigate(['profile']);
        }
        else{
          Swal.fire('User ID or Password not matched !');
        }
      });
  }

  register = () =>{
    this.Router.navigate(['register']);
  }

}
