import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../service/user.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @ViewChild('name') name:any; // accessing the reference element
  @ViewChild('email') email:any; // accessing the reference element
  @ViewChild('number') number:any; // accessing the reference element
  @ViewChild('subject') subject:any; // accessing the reference element
  @ViewChild('message') message:any; // accessing the reference element

  constructor(private UserService:UserService) { }

  ngOnInit(): void {
  }

  contactInformation = (formData:any) =>{
    this.UserService.contactUs(formData).subscribe((contact)=>{
      console.log(contact);
      this.name.nativeElement.value = ' ';
      this.email.nativeElement.value = ' ';
      this.number.nativeElement.value = ' ';
      this.subject.nativeElement.value = ' ';
      this.message.nativeElement.value = ' ';
    });
  }
}
