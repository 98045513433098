import { Component,ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  constructor() { }

  @ViewChild('paypalref', {static: true}) private paypalref: ElementRef;
  ngOnInit(): void {
    window.paypal.Buttons(
      {
        style:{
          layout:'horizontal'
        },
        createOrder: (data,action) => {
           return action.order.create({
            purchase_units: [
              {
                amount:{
                  value:'1000',
                  currency_code:'USD',
                }
              }
            ]
           });
        },
        onApprove:(data, action) => {
          return action.order.capture().then((details)=>{
            alert('payment success');
          });
        },

        onError:(data, action)=>{
          alert(data);
        }
      }
    ).render(this.paypalref.nativeElement);
  }

}
 