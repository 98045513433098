import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { CartComponent } from './cart/cart.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProductComponent } from './product/product.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { SearchproductComponent } from './searchproduct/searchproduct.component';
import { AuthGuard } from "./guard/auth.guard";
import { TeamComponent } from './team/team.component';
import { MethodologyComponent } from './methodology/methodology.component';
import { ContactComponent } from './contact/contact.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';

const routes: Routes = [
  {
    path:'',
    component: HomeComponent
  },
  {
    path:'products/:slug',
    component: ProductComponent
  },
  {
    path:'products-details/:slug',
    component: ProductdetailsComponent
  },
  {
    path:'cart',
    component: CartComponent
  },
  {
    path:'about',
    component: AboutComponent
  },
  {
    path:'search/:value',
    component: SearchproductComponent
  },
  {
    path:'dummyroute',
    component: SearchproductComponent
  },
  {
    path:'register',
    component: RegisterComponent
  },
  {
    path:'login',
    component: LoginComponent
  }
  ,
  {
    path:'profile', component:ProfileComponent, canActivate:[AuthGuard]
  },
  {
    path:'team',
    component:TeamComponent
  },
  {
    path:'methodology',
    component:MethodologyComponent
  },
  {
    path:'contact-us',
    component:ContactComponent
  },
  {
    path:'subcategories/:id',
    component:SubcategoriesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
