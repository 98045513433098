
<header class="section-header d-md-block d-none">
    <section class="header-main border-bottom">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-3 col-6 order-1 order-md-1">
                    <a routerLink='/' class="brand-wrap">
                        <img class="logo" src="assets/images/logo.png?v=2.0">
                    </a> <!-- brand-wrap.// -->
                </div>
                <div class="col-md-6 col-12 order-3 order-md-2">
                    <form action="#" class="search-header" #searchForm="ngForm" (ngSubmit)="getSearchInformation(searchForm.value)">
                        <div class="input-group w-100">
                            <input type="text" class="form-control" placeholder="Search" name="search" ngModel #search="ngModel"> 
                            
                            <div class="input-group-append">
                              <button class="btn btn-primary" type="submit" style=" border-radius: 0px;">
                                <i class="fa fa-search"></i> Search
                              </button>
                            </div>
                        </div>
                    </form> <!-- search-wrap .end// -->
                </div> <!-- col.// -->
                <div class="col-md-3 col-6 order-2 order-md-3">
                    <div class="widgets-wrap float-right">
                        <div class="widget-header mr-3" *ngIf="isLoggedIn">
                            <a routerLink="profile" class="widget-view">
                                <div class="icon-area">
                                    <i class="fa fa-user"></i>
                                </div>
                                <small class="text"> My profile </small>
                            </a>
                        </div>

                        <div class="widget-header mr-3" *ngIf="isLoggedIn == false">
                            <a routerLink="login" class="widget-view">
                                <div class="icon-area">
                                    <i class="fa fa-user"></i>
                                </div>
                                <small class="text"> My profile </small>
                            </a>
                        </div>

                        <div class="widget-header">
                            <a href="javascript:void(0)" (click)="cart()" class="widget-view">
                                <div class="icon-area">
                                    <i class="fa fa-shopping-cart"></i>
                                    <span class="notify">{{ cartQty }}</span>
                                </div>
                                <small class="text"> Cart </small>
                            </a>
                        </div>
                    </div> <!-- widgets-wrap.// -->
                </div> <!-- col.// -->
            </div> <!-- row.// -->
        </div> <!-- container.// -->
    </section> <!-- header-main .// -->
    
    <nav class="navbar navbar-main navbar-expand-lg border-bottom header-color">
        <div class="container">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav" aria-controls="main_nav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
        
            <div class="collapse navbar-collapse justify-content-md-center" id="main_nav" >
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/']" routerLinkActive="active-menu" [routerLinkActiveOptions]="{exact: true}">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu" routerLink="/about">About Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu"  routerLink="/team">Our Team</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu"  routerLink="/methodology">Methodology</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu" routerLink="/contact-us">Contact Us</a>
                </li>
            </ul>
            </div> <!-- collapse .// -->
        </div> <!-- container .// -->
    </nav>
</header> <!-- section-header.// -->
<header class="section-header d-md-none d-block">
    <section class="header-main border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <a routerLink='/' class="brand-wrap">
                        <img class="logo" src="assets/images/logo.png?v=2.0" style="    width: 200px;
                        max-height: 60px;">
                    </a> <!-- brand-wrap.// -->
                </div>
                <div class="col-6 ">
                    <div class="widgets-wrap float-right">
                        <div class="widget-header mr-3" *ngIf="isLoggedIn">
                            <a routerLink="profile" class="widget-view">
                                <div class="icon-area">
                                    <i class="fa fa-user"></i>
                                </div>
                                <small class="text"> My profile </small>
                            </a>
                        </div>

                        <div class="widget-header mr-3" *ngIf="isLoggedIn == false">
                            <a routerLink="login" class="widget-view">
                                <div class="icon-area">
                                    <i class="fa fa-user"></i>
                                </div>
                                <small class="text"> My profile </small>
                            </a>
                        </div>

                        <div class="widget-header">
                            <a href="javascript:void(0)" (click)="cart()" class="widget-view">
                                <div class="icon-area">
                                    <i class="fa fa-shopping-cart"></i>
                                    <span class="notify">{{ cartQty }}</span>
                                </div>
                                <small class="text"> Cart </small>
                            </a>
                        </div>
                    </div> <!-- widgets-wrap.// -->
                </div> <!-- col.// -->
            </div> <!-- row.// -->
        </div> <!-- container.// -->
    </section> <!-- header-main .// -->
    <nav class="navbar navbar-main navbar-expand-lg border-bottom header-color">
        <div class="container">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav" aria-controls="main_nav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
        
            <div class="collapse navbar-collapse justify-content-md-center" id="main_nav" >
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/']" routerLinkActive="active-menu" [routerLinkActiveOptions]="{exact: true}">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu" routerLink="/about">About Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu"  routerLink="/team">Our Team</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu"  routerLink="/methodology">Methodology</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript::void(0)" routerLinkActive="active-menu" routerLink="/contact-us">Contact Us</a>
                </li>
            </ul>
            </div> <!-- collapse .// -->
        </div> <!-- container .// -->
    </nav>
</header> <!-- section-header.// -->
