<section>
    <div class="container-fluid tagline-sec box-shadowsec" style="background-image: url('assets/images/banners/guy-mopping-dust-sofa-apartment.jpg'); ">
       <div class="row justify-content-md-center">
          <div class="col-md-8 taglin-conte2">
             <h1 class="text-center" style="font-size:34px">DON'T JUST CLEAN, GET IT CLEANED THE "UNIK" WAY ...</h1>
             <p class="text-center">Established in 2011 to change the face of Commercial cleaning activities; we parade a talented team of experienced personnel who handle all projects with precise attention to detail and a personal touch</p>
          </div>
       </div>
    </div>
 </section>
 <section id="team">
    <div class="container my-3 py-5 text-center">
       <div class="row mb-5">
          <div class="col">
             <h2>OUR HUMAN CAPITAL</h2>
             <p class="my-3">
                Our staff with their professional approach are each individually inducted, fully trained and highly motivated to ensure the stringent standards expected by our clients is delivered all the time.
                All our Directors and shareholders have an unrivaled commitment to the Success of the company. The quest for Excellence, Professionalism and a determination to provide Value for Money services in all Client engagements is the Hallmark of a Unik Janitorial Services staff.
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col-lg-4 col-md-6 mar-20">
             <div class="card-2 h-100">
                <div class="card-body">
                   <img src="assets/images/team-1.jpg" class="card-img-top">
                   <h5>FRED LAW</h5>
                   <p class="text-center desicnation">MD / CEO</p>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada faucibus ex nec ultricies. Donec mattis egestas nisi non pretium. Suspendisse nec eros ut erat facilisis maximus. In congue et leo in varius. Vestibulum sit amet felis ornare, commodo orci ut, feugiat lorem.</p>
                </div>
             </div>
          </div>
          <div class="col-lg-4 col-md-6 mar-20">
             <div class="card-2 h-100">
                <div class="card-body">
                   <img src="assets/images/team-2.jpg" class="card-img-top">
                   <h5>FRED LAW</h5>
                   <p class="text-center desicnation">ED - SALES & MARKETING</p>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada faucibus ex nec ultricies. Donec mattis egestas nisi non pretium. Suspendisse nec eros ut erat facilisis maximus. In congue et leo in varius. Vestibulum sit amet felis ornare, commodo orci ut, feugiat lorem.</p>
                </div>
             </div>
          </div>
          <div class="col-lg-4 col-md-6 mar-20">
             <div class="card-2 h-100">
                <div class="card-body">
                   <img src="assets/images/team-3.jpg" class="card-img-top">
                   <h5>FRED LAW</h5>
                   <p class="text-center desicnation">GM - CLIENT SERVICES</p>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada faucibus ex nec ultricies. Donec mattis egestas nisi non pretium. Suspendisse nec eros ut erat facilisis maximus. In congue et leo in varius. Vestibulum sit amet felis ornare, commodo orci ut, feugiat lorem.</p>
                </div>
             </div>
          </div>
          <div class="col-lg-4 col-md-6 mar-20">
             <div class="card-2 h-100">
                <div class="card-body">
                   <img src="assets/images/team-4.jpg" class="card-img-top">
                   <h5>JOE CASH</h5>
                   <p class="text-center desicnation">HEAD - FINANCE & ACCOUNTS</p>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada faucibus ex nec ultricies. Donec mattis egestas nisi non pretium. Suspendisse nec eros ut erat facilisis maximus. In congue et leo in varius. Vestibulum sit amet felis ornare, commodo orci ut, feugiat lorem.</p>
                </div>
             </div>
          </div>
          <div class="col-lg-4 col-md-6 mar-20">
             <div class="card-2 h-100">
                <div class="card-body">
                   <img src="assets/images/team-5.jpg" class="card-img-top">
                   <h5>ANNET STAR</h5>
                   <p class="text-center desicnation">HEAD - BUSINESS DEVT</p>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada faucibus ex nec ultricies. Donec mattis egestas nisi non pretium. Suspendisse nec eros ut erat facilisis maximus. In congue et leo in varius. Vestibulum sit amet felis ornare, commodo orci ut, feugiat lorem.</p>
                </div>
             </div>
          </div>
          <div class="col-lg-4 col-md-6 mar-20">
             <div class="card-2 h-100">
                <div class="card-body">
                   <img src="assets/images/team-6.jpg" class="card-img-top">
                   <h5>OMAR CATCH</h5>
                   <p class="text-center desicnation">HEAD - OPERATIONS</p>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada faucibus ex nec ultricies. Donec mattis egestas nisi non pretium. Suspendisse nec eros ut erat facilisis maximus. In congue et leo in varius. Vestibulum sit amet felis ornare, commodo orci ut, feugiat lorem.</p>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>