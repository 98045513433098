<section class="py-3 bg-light mb-30">
    <div class="container">
       <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item">{{ services.data.category_name | titlecase }}</li>
          <li class="breadcrumb-item">{{ services.data.subcategory_name | titlecase }}</li>
          <li class="breadcrumb-item active" aria-current="page">{{ services.data.service_name }}</li>
       </ol>
    </div>
 </section>
 <!-- ========================= SECTION CONTENT ========================= -->
 <section class="section-content bg-white padding-y">
    <div class="container">
       <!-- ============================ ITEM DETAIL ======================== -->
       <div class="row">
          <div class="col-md-7 mb-3">
             <div style="border-bottom: 2px solid #ccc; display: block; margin-bottom: 20px; padding-bottom: 10px;">
                <h5 class="title-description">{{ services.data.service_name }}</h5>
                <span class="text-muted">{{ services.data.service_short_des }}.</span> 
             </div>
             <h5 class="title-description">Inclusions & Exclusions</h5>
             
             <ul class="list-check">
                <li *ngFor="let inc of inclusion">{{ inc }}.</li>
             </ul>
             <ul class="list-minus">
                <li *ngFor="let exc of exclusion">{{ exc }}.</li>
             </ul>
             <h5 class="title-description">Procedure</h5>
             <ul class="list-check">
                <span [innerHTML]='services.data.procedure'> </span>
             </ul>
          </div>
          <!-- col.// -->
          <main class="col-md-5 mb-3">
             <div class="add-sect">
                <div class="tile" id="tile-1">
                   <!-- Nav tabs -->
                   <ul class="nav nav-tabs nav-justified" role="tablist">
                     <li class="nav-item" *ngFor="let price of prices | keyvalue">
                        <a [ngClass]="{active:price.key == active}" (click)="changeKey(price.key,price.value.id)">{{ price.value.name }}</a>
                      </li>
                  </ul>
                   <!-- Tab panes -->
                   <div class="tab-content">
                      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div *ngIf="loader">
                           <img src="assets/loader/Pulse-1s-200px.gif" class="loader" />
                        </div> 
                        <div class="row">
                            <div class="col-md-8">
                               <p class="cout">Units</p>
                            </div>
                            <div class="col-md-4">
                               <div class="input-group mb-3 input-spinner">
                                  <div class="input-group-prepend">
                                    <button class="btn btn-light" (click)="decreaseVal()" type="button" id="button-minus"> &minus; </button>
                                  </div>
                                  <input type="text" class="form-control"  value="{{ qty }}">
                                  <div class="input-group-append">
                                     <button class="btn btn-light" (click)="increaseVal()" type="button" id="button-plus"> + </button>
                                    </div>
                               </div>
                            </div>
                         </div>
                         <div class="row">
                            <div class="col-md-8">
                               <p class="cout">Sub total</p>
                            </div>
                            <div class="col-md-4">
                               <h2>${{ subTotal }}</h2>
                            </div>
                         </div>
                      </div>
                      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                         <div class="row">
                            <div class="col-md-8">
                               <p class="cout">Units</p>
                            </div>
                            <div class="col-md-4">
                               <div class="input-group mb-3 input-spinner">
                                  <div class="input-group-prepend">
                                     <button class="btn btn-light" type="button" id="button-plus"> + </button>
                                  </div>
                                  <input type="text" class="form-control" value="{{ maxValue }}">
                                  <div class="input-group-append">
                                     <button class="btn btn-light" type="button" id="button-minus"> &minus; </button>
                                  </div>
                               </div>
                            </div>
                         </div>
                         <div class="row">
                            <div class="col-md-8">
                               <p class="cout">Sub total</p>
                            </div>
                            <div class="col-md-4">
                               <h2>$2200</h2>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
                <div class="form-group col-md" style="position: absolute; bottom:0px;">
                   <a href="javascript:void(0)" (click)="addToCart()" class="btn  btn-primary" style="margin:10px;"> 
                   <i class="fas fa-shopping-cart"></i> <span class="text">Add to cart</span> 
                   </a>
                   <a href="#" class="btn btn-light" style="margin:10px;">
                   <i class="fas fa-envelope"></i> <span class="text">Contact supplier</span> 
                   </a>
                </div>
             </div>
          </main>
          <!-- col.// -->
       </div>
       <!-- row.// -->
       <!-- ================ ITEM DETAIL END .// ================= -->
    </div>
    <!-- container .//  -->
 </section>